import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import Back from '../components/button/back';

function RevisiNilaiSiswa() {
    const { id, questionId } = useParams();
    const [answers, setAnswers] = useState([]);
    const [DataSiswa, setDataSiswa] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isRevised, setIsRevised] = useState(false);


    // Fungsi untuk menangani perubahan nilai revisi
    const handleRevisiChange = (answerId, value, index, isMultiple) => {
        setAnswers((prevAnswers) =>
            prevAnswers.map((answer) =>
                answer._id === answerId
                    ? {
                        ...answer,
                        question: answer.question.map((q, qIndex) =>
                            qIndex === index
                                ? {
                                    ...q,
                                    revisiNilai: isMultiple ? undefined : parseFloat(value), // For multipleChoice, skip revisiNilai
                                    // Update isCorrect for multipleChoice (value 'Benar' -> true, 'Salah' -> false)
                                    isCorrect: isMultiple ? (value === 'Benar' ? true : false) : q.isCorrect,
                                }
                                : q
                        ),
                    }
                    : answer
            )
        );
    };




    // Fungsi untuk menyimpan revisi nilai ke server
    const handleSaveRevisi = async (answerId, studentId, questionIndex) => {
        try {
            setLoading(true);

            // Cari jawaban yang sesuai
            const answerToSave = answers.find(
                (answer) => answer._id === answerId && answer.studentId._id === studentId
            );
            if (!answerToSave) {
                alert("Answer not found for this student.");
                return;
            }

            // Ambil pertanyaan yang akan diperbarui
            const updatedQuestion = answerToSave.question[questionIndex];
            const revisiNilai = parseFloat(updatedQuestion.revisiNilai);
            let isCorrect = updatedQuestion.isCorrect;

            if (updatedQuestion.type === 'multipleChoice') {
                if (isCorrect === undefined) {
                    alert("Pilihan jawaban tidak valid.");
                    return;
                }
            } else {
                // Validasi untuk soal selain multipleChoice
                if (isNaN(revisiNilai) || revisiNilai < 0 || revisiNilai > updatedQuestion.bobot) {
                    alert("Nilai revisi tidak valid.");
                    return;
                }
            }

            const token = localStorage.getItem("token");

            // Kirim data revisi ke API
            await axios.put(
                `${process.env.REACT_APP_API_URL}api/answers/update`,
                {
                    studentId,
                    questionId: updatedQuestion._id,
                    revisiNilai,
                    isCorrect

                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            alert("Revisi nilai berhasil disimpan!");
            setIsRevised(true);
            console.log(isRevised);
        } catch (error) {
            console.error("Error saving revisi nilai:", error);
            alert("Gagal menyimpan revisi nilai. Coba lagi.");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const fetchAnswers = async () => {
            try {
                const token = localStorage.getItem("token");
                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL}api/answers/question/${questionId}`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );

                // Filter hanya data yang sesuai dengan studentId dari URL
                const dataFilter = response.data.filter((data) => data.studentId._id === id);

                // Tambahkan properti `revisiNilai` ke setiap pertanyaan jika belum ada
                const updatedData = dataFilter.map((answer) => ({
                    ...answer,
                    question: answer.question.map((q) => ({
                        ...q,
                        revisiNilai: null, // Default nilai revisi = score sebelumnya
                    })),
                }));

                setAnswers(updatedData);
            } catch (error) {
                console.error("Error fetching answers:", error);
            }
        };

        const fetchSiswa = async () => {
            try {
                const token = localStorage.getItem("token");
                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL}api/auth/users/${id}`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                const siswa = response.data;
                setDataSiswa(siswa);
            } catch (error) {
                console.error("Error fetching answers:", error);
            }
        };
        fetchSiswa();
        fetchAnswers();
        if (isRevised) {
            fetchAnswers();
        }
        setIsRevised(false)
    }, [questionId, id, isRevised]);




    const getColorClass = (isCorrect) => {
        return isCorrect ? 'text-green-800' : 'text-red-800';
    };

    return (
        <div className="h-screen p-5">
            <h1 className="text-xl font-bold mb-4">Revisi Nilai Siswa</h1>
            <h2 className="text-lg mb-2">Nama Siswa: {DataSiswa.name}</h2>
            <h2 className="text-lg mb-2">Kelas: {DataSiswa.kelas} {DataSiswa.rombel}</h2>
            <div>
                {answers.length === 0 ? (
                    <p>Loading answers...</p>
                ) : (
                    <div className="overflow-y-auto max-h-[calc(100vh-160px)]"> {/* Set max height of table container */}
                        <table className="table-auto border-collapse border border-gray-300 w-full ">
                            <thead className="bg-gray-800 text-white">
                                <tr>
                                    <th className="px-4 py-2 border w-[5%]">Nomor</th>
                                    <th className="px-4 py-2 border w-[20%]">Pertanyaan</th>
                                    <th className="px-4 py-2 border w-[15%]">Tipe Soal</th>
                                    <th className="px-4 py-2 border w-[15%]">Kunci Jawaban</th>
                                    <th className="px-4 py-2 border w-[15%]">Jawaban</th>
                                    <th className="px-4 py-2 border w-[10%]">Bobot</th>
                                    <th className="px-4 py-2 border w-[10%]">Skor</th>
                                    <th className="px-4 py-2 border w-[10%]">Revisi</th>
                                    <th className="px-4 py-2 border w-[5%]">Aksi</th>
                                </tr>
                            </thead>
                            <tbody>
                                {answers.map((answer) =>
                                    answer.question.map((q, index) => (
                                        <tr key={q._id} className="hover:bg-gray-100">
                                            <td className="px-4 py-2 border text-center">{index + 1}</td>
                                            <td className="px-4 py-2 border">{q.text}</td>
                                            <td className="px-4 py-2 border text-center">
                                                {q.type === 'essay' ? 'Essay' :
                                                    q.type === 'isian' ? 'Isian Singkat' :
                                                        q.type === 'multipleChoice' ? 'Pilihan Ganda' :
                                                            'Unknown Type'} {/* Fallback if type is not one of the above */}
                                            </td>
                                            <td className="px-4 py-2 border text-left">
                                                {q.type === 'isian' ? (
                                                    // Split the user answer by "|" and render each part as a list item
                                                    <ul>
                                                        {q.optionCorrectAnswer ? (
                                                            q.optionCorrectAnswer.split('|').map((answer, index) => (
                                                                <li key={index}>- {answer.trim()}</li> // Trim any extra spaces
                                                            ))
                                                        ) : (
                                                            <p>Data Kunci Jawaban Isian tidak tersimpan</p>
                                                        )}
                                                    </ul>
                                                ) : (
                                                    // Default for other types
                                                    <p>{q.correctAnswer}</p>
                                                )}

                                            </td>
                                            <td className="px-4 py-2 border text-left">
                                                {q.type === 'isian' ? (
                                                    // Split the user answer by "|" and render each part as a list item
                                                    <ul>
                                                        {q.userAnswer.split('|').map((answer, index) => (
                                                            <li key={index}>- {answer.trim()}</li> // Trim any extra spaces
                                                        ))}
                                                    </ul>
                                                ) : (
                                                    // Default for other types
                                                    q.userAnswer
                                                )}
                                            </td>

                                            <td className="px-4 py-2 border text-center">{q.bobot}</td>
                                            <td className="px-4 py-2 border text-center">{q.score}</td>
                                            <td className="px-4 py-2 border">
                                                {q.type === 'multipleChoice' ? (
                                                    <select
                                                        value={q.isCorrect ? 'Benar' : 'Salah'}
                                                        className={`p-2 rounded ${getColorClass(q.isCorrect)}`}
                                                        onChange={(e) => handleRevisiChange(answer._id, e.target.value, index, true)}
                                                    >
                                                        <option value='Benar' className="text-green-500">Benar</option>
                                                        <option value='Salah' className="text-red-500">Salah</option>
                                                    </select>
                                                ) : (
                                                    <input
                                                        type="number"
                                                        placeholder="Revisi Nilai"
                                                        value={q.revisiNilai || ''}
                                                        min={0}
                                                        max={q.bobot}
                                                        onChange={(e) => handleRevisiChange(answer._id, e.target.value, index)}
                                                        className="border p-1 w-full"
                                                    />
                                                )}
                                            </td>
                                            <td className="px-4 py-2 border">
                                                <button
                                                    onClick={() => handleSaveRevisi(answer._id, answer.studentId._id, index)}
                                                    className="bg-blue-500 text-white px-3 py-1 rounded"
                                                    disabled={loading}
                                                >
                                                    {loading ? "Menyimpan..." : "Simpan"}
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>
                        <div className="flex justify-center mt-20">
                            <Link to={"/guru"}>
                                <Back />
                            </Link>
                        </div>
                    </div>
                )}
            </div>
        </div>


    );
}


export default RevisiNilaiSiswa;
