import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Back from "../button/back";

const ListP5Rapor = () => {
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({
    kelas: "",
    semester: "",
    tahun: "",
  });
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}api/p5rapor/all`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setData(response.data);
        setFilteredData(response.data); // Initial filtered data same as fetched data
      } catch (error) {
        console.error("Error fetching P5Rapor data:", error);
        toast.error("Failed to fetch data. Please try again later.");
      }
    };

    fetchData();
  }, []);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  useEffect(() => {
    const filterData = () => {
      let tempData = data;

      if (filters.kelas) {
        tempData = tempData.filter((item) => item.kelas === filters.kelas);
      }
      if (filters.semester) {
        tempData = tempData.filter(
          (item) => item.semester === filters.semester
        );
      }
      if (filters.tahun) {
        tempData = tempData.filter((item) => item.tahun === filters.tahun);
      }
      setFilteredData(tempData);
    };

    filterData();
  }, [filters, data]);

  const handleCheckboxChange = (e, itemId, subItemId, type) => {
    const { checked } = e.target;
    setFilteredData((prevData) =>
      prevData.map((item) =>
        item._id === itemId
          ? {
              ...item,
              data: item.data.map((dataItem) => ({
                ...dataItem,
                item: dataItem.item.map((subItem) =>
                  subItem._id === subItemId
                    ? { ...subItem, [type]: checked }
                    : subItem
                ),
              })),
            }
          : item
      )
    );
  };

  const handleCatatanProsesChange = (e, itemId, nama) => {
    const { value } = e.target;
    setFilteredData((prevData) =>
      prevData.map((item) =>
        item._id === itemId
          ? {
              ...item,
              data: item.data.map((dataItem) =>
                dataItem.nama === nama
                  ? { ...dataItem, catatanProses: value }
                  : dataItem
              ),
            }
          : item
      )
    );
  };

  const handleSubmit = async (itemId) => {
    try {
      const token = localStorage.getItem("token");
      const updatedItem = filteredData.find((item) => item._id === itemId);

      if (!updatedItem) {
        toast.error("Data not found");
        return;
      }

      await axios.put(
        `${process.env.REACT_APP_API_URL}api/p5rapor/update/${itemId}`,
        updatedItem,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success("Data updated successfully");
    } catch (error) {
      console.error("Error updating P5Rapor data:", error);
      toast.error("Failed to update data. Please try again later.");
    }
  };

  // Check if all filters are filled
  const allFiltersFilled =
    filters.kelas !== "" && filters.semester !== "" && filters.tahun !== "";

  return (
    <div className="p-4 font-arial">
      <ToastContainer />
      <h1 className="text-xl font-bold mb-4">List P5 Rapor</h1>
      <div className="mb-4 flex flex-wrap">
        <div className="mr-4 mb-4">
          <label className="mr-2">Kelas:</label>
          <select
            name="kelas"
            value={filters.kelas}
            onChange={handleFilterChange}
            className="border border-gray-300 p-2 rounded-md"
          >
            <option value="">Semua</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
          </select>
        </div>
        <div className="mr-4 mb-4">
          <label className="mr-2">Semester:</label>
          <select
            name="semester"
            value={filters.semester}
            onChange={handleFilterChange}
            className="border border-gray-300 p-2 rounded-md"
          >
            <option value="">Semua</option>
            <option value="1">1</option>
            <option value="2">2</option>
          </select>
        </div>
        <div className="mr-4 mb-4">
          <label className="mr-2">Tahun:</label>
          <input
            type="text"
            name="tahun"
            value={filters.tahun}
            onChange={handleFilterChange}
            className="border border-gray-300 p-2 rounded-md"
            placeholder="Contoh: 2024"
          />
        </div>
      </div>
      {allFiltersFilled && filteredData.length === 0 ? (
        <div>
          <div className="flex justify-center mb-5">
            <p>No data available for selected filters.</p>
          </div>
          <div className="flex justify-center">
            <Link to={"/addp5"}>
              <button className="px-3 py-2 bg-blue-400 hover:bg-blue-700 rounded-lg text-white">
                Add rapor P5
              </button>
            </Link>
          </div>
        </div>
      ) : allFiltersFilled ? (
        <div className="overflow-x-auto">
          <div>
            {filteredData.map((item) => (
              <div key={item._id}>
                <div className="font-bold">
                  <h1 className="">{item.namaproyek}</h1>
                </div>
                <div className="border border-slate-300 p-2 rounded-lg mb-5">
                  <h1>{item.deskripsiproyek}</h1>
                </div>
              </div>
            ))}
          </div>
          <table className="min-w-full border border-gray-300 text-[12px]">
            <thead>
              <tr>
                <th className="border border-gray-300 w-5">No</th>
                <th className="border border-gray-300 w-52">Nama</th>
                <th className="border border-gray-300 w-52">Catatan Proses</th>
                <th className="border border-gray-300 w-52">Element</th>
                <th className="border border-gray-300 w-20">MB</th>
                <th className="border border-gray-300 w-20">SB</th>
                <th className="border border-gray-300 w-20">BSH</th>
                <th className="border border-gray-300 w-20">SGB</th>
                <th className="border border-gray-300 w-20">Aksi</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((item, index) => (
                <React.Fragment key={item._id}>
                  {item.data.map((dataItem) => (
                    <tr key={dataItem._id}>
                      <td className="border border-gray-300">{index + 1}</td>
                      <td className="border border-gray-300">
                        <div className="w-32 flex justify-center">
                          {dataItem.nama}
                        </div>
                      </td>
                      <td className="border border-gray-300">
                        <div className="w-96 sm:auto h-20">
                          <input
                            type="text"
                            value={dataItem.catatanProses}
                            onChange={(e) =>
                              handleCatatanProsesChange(
                                e,
                                item._id,
                                dataItem.nama
                              )
                            }
                            className="border border-gray-300 p-2 rounded-md w-full"
                          />
                        </div>
                      </td>
                      <td className="border border-gray-300">
                        {dataItem.item.map((subItem) => (
                          <div key={subItem._id} className="w-96 sm:auto h-20">
                            <strong>{subItem.element}</strong>
                            <h1>{subItem.subelement}</h1>
                          </div>
                        ))}
                      </td>

                      <td className="border border-gray-300">
                        {dataItem.item.map((subItem) => (
                          <div
                            key={subItem._id}
                            className="w-20 h-20 flex justify-center"
                          >
                            <input
                              type="checkbox"
                              checked={subItem.MB}
                              onChange={(e) =>
                                handleCheckboxChange(
                                  e,
                                  item._id,
                                  subItem._id,
                                  "MB"
                                )
                              }
                            />
                          </div>
                        ))}
                      </td>
                      <td className="border border-gray-300">
                        {dataItem.item.map((subItem) => (
                          <div
                            key={subItem._id}
                            className="w-20 h-20 flex justify-center"
                          >
                            <input
                              type="checkbox"
                              checked={subItem.SB}
                              onChange={(e) =>
                                handleCheckboxChange(
                                  e,
                                  item._id,
                                  subItem._id,
                                  "SB"
                                )
                              }
                            />
                          </div>
                        ))}
                      </td>
                      <td className="border border-gray-300">
                        {dataItem.item.map((subItem) => (
                          <div
                            key={subItem._id}
                            className="w-20 h-20 flex justify-center"
                          >
                            <input
                              type="checkbox"
                              checked={subItem.BSH}
                              onChange={(e) =>
                                handleCheckboxChange(
                                  e,
                                  item._id,
                                  subItem._id,
                                  "BSH"
                                )
                              }
                            />
                          </div>
                        ))}
                      </td>
                      <td className="border border-gray-300">
                        {dataItem.item.map((subItem) => (
                          <div
                            key={subItem._id}
                            className="w-20 h-20 flex justify-center"
                          >
                            <input
                              type="checkbox"
                              checked={subItem.SGB}
                              onChange={(e) =>
                                handleCheckboxChange(
                                  e,
                                  item._id,
                                  subItem._id,
                                  "SGB"
                                )
                              }
                            />
                          </div>
                        ))}
                      </td>
                      <td className="border border-gray-300 w-5">
                        <div>
                          <div>
                            <button
                              className="px-3 py-2 mx-3 rounded-lg text-white bg-blue-400"
                              onClick={() => handleSubmit(item._id)}
                            >
                              Update
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p>Silakan isi semua filter untuk menampilkan data.</p>
      )}
      <div className="flex justify-center mt-5 mb-10">
        <Link to={"/guru" || "/admin"}>
          <Back />
        </Link>
      </div>
    </div>
  );
};

export default ListP5Rapor;
