import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Back from "../components/button/back";

const EditUser = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useState({
    username: "",
    password: null,
    role: "",
    NISN: "",
    NIPD: "",
    NIP: "",
    statusPPH: "",
    jabatan: "",
    name: "",
    kelamin: "",
    TL: "",
    TGL: "",
    kelas: "",
    rombel: "",
    mapel: "",
    image: "",
    date: "",
  });

  const [file, setFile] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}api/auth/users/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const userData = response.data;

        const formattedTGL = userData.TGL
          ? new Date(userData.TGL).toISOString().split("T")[0]
          : "";

        setUser({
          ...userData,
          TGL: formattedTGL,
          password: null,
        });
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUser();
  }, [id]);

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleImageUpload = async () => {
    if (!file){
      return "";
    }
    const imageData = new FormData();
    imageData.append("image", file);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}api/auth/upload`,
        imageData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      return response.data.image_url;
    } catch (error) {
      console.error("Error uploading image:", error);
      return "";
    }
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const imageUrl = await handleImageUpload();
    const updatedUser = { ...user, image: imageUrl };
  
    // Kirim gambar lama untuk dihapus (jika ada) bersama dengan data update
    const oldImage = user.image; // Ambil gambar lama
  
    try {
      const token = localStorage.getItem("token");
      await axios.put(
        `${process.env.REACT_APP_API_URL}api/auth/users/${id}`,
        { ...updatedUser, oldImage: oldImage }, // Kirim oldImage ke backend
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      navigate("/admin");
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };
  

  return (
    <div className="grid justify-center min-h-screen bg-blue-100">
      <div className="px-5 py-5">
        <div>
          <h1 className=" flex justify-center py-5 text-3xl font-bold">
            Edit User
          </h1>
        </div>
        <div className="bg-white rounded-lg drop-shadow-lg p-5">
          <form onSubmit={handleSubmit}>
            <div className="grid gap-y-3 border border-slate-500/40 rounded-lg p-5">
              <div>
                <label className="font-bold">Username : </label>
                <input
                  type="text"
                  name="username"
                  value={user.username || ""}
                  onChange={handleChange}
                  className="border border-slate-500/40 rounded-md p-1"
                />
              </div>
              <div>
                <label className="font-bold">Password : </label>
                <input
                  type="password" // Change type to password
                  name="password"
                  onChange={handleChange}
                  placeholder="Leave blank to keep current password"
                  className="border border-slate-500/40 rounded-md p-1"
                />
              </div>
              <div>
                <label className="font-bold">Role : </label>
                <select
                  name="role"
                  value={user.role || ""}
                  onChange={handleChange}
                  className="border border-slate-500/40 rounded-md p-1"
                >
                  <option value="">Pilih Role</option>
                  <option value="admin">Admin</option>
                  <option value="guru">Guru</option>
                  <option value="siswa">Siswa</option>
                </select>
              </div>
              {user.role === "siswa" && (
                <>
                  <div>
                    <label className="font-bold">NISN : </label>
                    <input
                      type="text"
                      name="NISN"
                      value={user.NISN || ""}
                      onChange={handleChange}
                      className="border border-slate-500/40 rounded-md p-1"
                    />
                  </div>
                  <div>
                    <label className="font-bold">NIPD : </label>
                    <input
                      type="text"
                      name="NIPD"
                      value={user.NIPD || ""}
                      onChange={handleChange}
                      className="border border-slate-500/40 rounded-md p-1"
                    />
                  </div>
                  <div>
                    <label className="font-bold">Kelas : </label>
                    <select
                      name="kelas"
                      value={user.kelas || ""}
                      onChange={handleChange}
                      className="border border-slate-500/40 rounded-md p-1"
                    >
                      <option value="">Pilih Kelas</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="lulus">lulus</option>
                    </select>
                  </div>
                  <div>
                    <label className="font-bold">Rombel : </label>
                    <select
                      name="rombel"
                      value={user.rombel || ""}
                      onChange={handleChange}
                      className="border border-slate-500/40 rounded-md p-1"
                    >
                      <option value="">Pilih Rombel</option>
                      <option value="A">A</option>
                      <option value="B">B</option>
                    </select>
                  </div>
                </>
              )}
              {user.role === "guru" && (
                <>
                  <div>
                    <label className="font-bold">NIP : </label>
                    <input
                      type="text"
                      name="NIP"
                      value={user.NIP || ""}
                      onChange={handleChange}
                      className="border border-slate-500/40 rounded-md p-1"
                    />
                  </div>
                  <div>
                    <label className="font-bold">Status : </label>
                    <select
                      name="statusPPH"
                      value={user.statusPPH || ""}
                      onChange={handleChange}
                      className="border border-slate-500/40 rounded-md p-1"
                    >
                      <option value="">Pilih Status</option>
                      <option value="PNS">PNS</option>
                      <option value="PPPK">PPPK</option>
                      <option value="Honorer">Honorer</option>
                    </select>
                  </div>
                  <div>
                    <label className="font-bold">Jabatan : </label>
                    <select
                      name="jabatan"
                      value={user.jabatan || ""}
                      onChange={handleChange}
                      className="border border-slate-500/40 rounded-md p-1"
                    >
                      <option value="">Pilih Jabatan</option>
                      <option value="Kepala Sekolah">Kepala Sekolah</option>
                      <option value="Guru Kelas">Guru Kelas</option>
                      <option value="Guru Mapel">Guru Mapel</option>
                    </select>
                  </div>
                  {user.jabatan === "Guru Kelas" && (
                    <>
                      <div>
                        <label className="font-bold">Kelas : </label>
                        <select
                          name="kelas"
                          value={user.kelas || ""}
                          onChange={handleChange}
                          className="border border-slate-500/40 rounded-md p-1"
                        >
                          <option value="">Pilih Kelas</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                        </select>
                      </div>
                      <div>
                        <label className="font-bold">Rombel : </label>
                        <select
                          name="rombel"
                          value={user.rombel || ""}
                          onChange={handleChange}
                          className="border border-slate-500/40 rounded-md p-1"
                        >
                          <option value="">Pilih Rombel</option>
                          <option value="A">A</option>
                          <option value="B">B</option>
                        </select>
                      </div>
                    </>
                  )}
                  {user.role === "guru" && user.jabatan === "Guru Mapel" && (
                    <div>
                      <label className="font-bold">Mata Pelajaran : </label>
                      <select
                        name="mapel"
                        value={user.mapel || ""}
                        onChange={handleChange}
                        className="border border-slate-500/40 rounded-md p-1"
                      >
                        <option value="">Pilih Mata Pelajaran</option>
                        <option value="IPA">IPA</option>
                        <option value="IPS">IPS</option>
                        <option value="MTK">MTK</option>
                        <option value="BIndo">BIndo</option>
                        <option value="BIng">BIng</option>
                        <option value="PAI">PAI</option>
                        <option value="PKN">PKN</option>
                        <option value="IPAS">IPAS</option>
                        <option value="PJOK">PJOK</option>
                        <option value="MULOK">MULOK</option>
                        <option value="Senibudaya">Senibudaya</option>
                      </select>
                    </div>
                  )}
                </>
              )}
              {user.role === "admin" && (
                <>
                  <div>
                    <label className="font-bold">Status : </label>
                    <select
                      name="statusPPH"
                      value={user.statusPPH || ""}
                      onChange={handleChange}
                      className="border border-slate-500/40 rounded-md p-1"
                    >
                      <option value="">Pilih Status</option>
                      <option value="PNS">PNS</option>
                      <option value="PPPK">PPPK</option>
                      <option value="Honorer">Honorer</option>
                    </select>
                  </div>
                  <div>
                    <label className="font-bold">NIP : </label>
                    <input
                      type="text"
                      name="NIP"
                      value={user.NIP || ""}
                      onChange={handleChange}
                      className="border border-slate-500/40 rounded-md p-1"
                    />
                  </div>
                </>
              )}
              <div>
                <label className="font-bold">Name : </label>
                <input
                  type="text"
                  name="name"
                  value={user.name || ""}
                  onChange={handleChange}
                  className="border border-slate-500/40 rounded-md p-1"
                />
              </div>
              <div>
                <label className="font-bold">Kelamin : </label>
                <select
                  name="kelamin"
                  value={user.kelamin || ""}
                  onChange={handleChange}
                  className="border border-slate-500/40 rounded-md p-1"
                >
                  <option value="">Laki-laki/Perempuan</option>
                  <option value="Laki-laki">Laki-laki</option>
                  <option value="Perempuan">Perempuan</option>
                </select>
              </div>
              <div>
                <label className="font-bold">Tempat Lahir : </label>
                <input
                  type="text"
                  name="TL"
                  value={user.TL || ""}
                  onChange={handleChange}
                  className="border border-slate-500/40 rounded-md p-1"
                />
              </div>
              <div>
                <label className="font-bold">Tanggal Lahir : </label>
                <input
                  type="date"
                  name="TGL"
                  value={user.TGL || ""}
                  onChange={handleChange}
                  className="border border-slate-500/40 rounded-md p-1"
                />
              </div>
              <div>
                <label className="font-bold">Image :</label>
                <input type="file" onChange={handleFileChange} />
              </div>
            </div>
            <div className="flex justify-center bg-blue-300 hover:bg-blue-600 text-white p-2 my-2 rounded-lg font-bold">
              <button type="submit">Update User</button>
            </div>
          </form>
        </div>
      </div>
      <div className="flex justify-center pb-10">
        <Link to={"/admin"}>
          <Back />
        </Link>
      </div>
    </div>
  );
};

export default EditUser;
