import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Back from '../../components/button/back';

function HalamanBerita() {
    const { id } = useParams();
    const [berita, setBerita] = useState(null);

    useEffect(() => {
        const fetchBerita = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}api/berita`);
                const data = await response.json();
                const beritaTerpilih = data.find((item) => item._id === id);
                setBerita(beritaTerpilih || null);
            } catch (error) {
                console.error('Error fetching berita:', error);
            }
        };

        fetchBerita();
    }, [id]);
    const formatTanggal = (tanggal) => {
        const date = new Date(tanggal);
        const day = String(date.getDate()).padStart(2, "0"); // Menambahkan leading zero jika kurang dari 10
        const monthNames = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "Mei",
          "Jun",
          "Jul",
          "Agu",
          "Sep",
          "Okt",
          "Nov",
          "Des",
        ];
        const month = monthNames[date.getMonth()]; // Mendapatkan nama bulan dalam format 3 huruf
        const year = date.getFullYear();
        return `${day} ${month} ${year}`;
      };
    return (
        <div className="max-w-6xl mx-auto p-6 font-sans text-gray-800">
            <Link to={"/"}>
                <Back />
            </Link>
            {berita ? (
                <div className="bg-white shadow-md rounded-lg overflow-hidden">
                    <img 
                        src={berita.gambar} 
                        alt={berita.judul} 
                        className="w-full h-64 object-cover"
                    />
                    <div className="p-6">
                        <h1 className="text-2xl font-bold text-center mb-4">{berita.judul}</h1>
                        <p className="text-sm text-gray-500 text-center mb-4">
                            <strong>Tanggal:</strong> {formatTanggal(berita.tanggal)}
                        </p>
                        <p className="text-lg text-gray-700 mb-4">
                             {berita.deskripsi}
                        </p>
                        <p className="text-xs text-gray-600 leading-relaxed">
                            <strong>Penulis:</strong> Admin
                        </p>
                    </div>
                </div>
            ) : (
                <p className="text-center text-gray-500">Loading berita...</p>
            )}
        </div>
    );
}

export default HalamanBerita;
