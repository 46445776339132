import React, { useState, useEffect } from "react";
import { FaArrowLeft, FaArrowRight, FaCircle } from "react-icons/fa";
import { Link } from "react-router-dom";

function AllBerita() {
    const [berita, setBerita] = useState([]); // Menyimpan data berita dari API
    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const beritaPerPage = 4;

    // Mengambil data berita dari API saat komponen dimuat
    useEffect(() => {
        const fetchBerita = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}api/berita`);
                const data = await response.json();
                setBerita(data); // Menyimpan data berita dalam state
            } catch (error) {
                console.error("Error fetching berita:", error);
            }
        };

        fetchBerita();
    }, []);

    // Fungsi Slider
    const handlePrevious = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? berita.length - 1 : prevIndex - 1
        );
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === berita.length - 1 ? 0 : prevIndex + 1
        );
    };

    const handleDotClick = (index) => {
        setCurrentIndex(index);
    };

    // Fungsi Pagination
    const indexOfLastBerita = currentPage * beritaPerPage;
    const indexOfFirstBerita = indexOfLastBerita - beritaPerPage;
    const currentBerita = berita.slice(indexOfFirstBerita, indexOfLastBerita);
    const totalPages = Math.ceil(berita.length / beritaPerPage);

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <div className="my-10 bg-white py-10 px-5 flex flex-col items-center">
            {/* Slider Berita Populer */}
            <div className="relative w-4/5 mb-10 border border-red-500 rounded-lg overflow-hidden">
                <img
                    src={berita[currentIndex]?.gambar} // Menggunakan optional chaining untuk menghindari error jika berita kosong
                    alt={berita[currentIndex]?.judul}
                    className="w-full h-96 object-cover"
                />
                <div className="absolute bottom-0 bg-black bg-opacity-50 text-white p-4 w-full">
                    <h3 className="text-lg font-bold">{berita[currentIndex]?.judul}</h3>
                    <p className="text-sm">{berita[currentIndex]?.deskripsi}</p>
                </div>
                {/* Tombol Previous */}
                <button
                    className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white px-3 py-2 rounded-full"
                    onClick={handlePrevious}
                >
                    &#8592;
                </button>
                {/* Tombol Next */}
                <button
                    className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white px-3 py-2 rounded-full"
                    onClick={handleNext}
                >
                    &#8594;
                </button>
                {/* Indikator Dots */}
                <div className="absolute bottom-2 left-1/2 transform -translate-x-1/2 flex space-x-2">
                    {berita.map((_, index) => (
                        <button
                            key={index}
                            onClick={() => handleDotClick(index)}
                            className={`w-3 h-3 rounded-full ${currentIndex === index ? "bg-red-500" : "bg-gray-400"}`}
                        ></button>
                    ))}
                </div>
            </div>

            {/* Kartu Berita Lainnya */}
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 w-4/5">
                {currentBerita.map((item) => (
                    <div
                        key={item.id}
                        className="border rounded-lg overflow-hidden shadow-md"
                    >
                        <Link to={`/berita/${item._id}`}>
                            <img
                                src={item.gambar}
                                alt={item.judul}
                                className="w-full h-48 object-cover"
                            />
                            <div className="p-4">
                                <h6 className="text-xs text-gray-500">{item.tanggal}</h6>
                                <h4 className="font-bold text-lg mb-2">{item.judul}</h4>
                                <p className="text-sm text-gray-700 mb-2">{item.deskripsi}</p>
                            </div>
                        </Link>
                    </div>
                ))}
            </div>

            {/* Indikator Pagination */}
            <div className="flex justify-center items-center space-x-4 mt-4">
                {/* Tombol Previous */}
                <button
                    className={`px-4 py-2 rounded ${currentPage === 1 ? "bg-gray-300 cursor-not-allowed" : "bg-red-500 text-white"}`}
                    onClick={handlePreviousPage}
                    disabled={currentPage === 1}
                >
                    <FaArrowLeft />
                </button>

                {/* Indikator Titik */}
                <div className="flex space-x-2">
                    {[...Array(totalPages)].map((_, index) => (
                        <button
                            key={index}
                            onClick={() => handlePageChange(index + 1)}
                            className={`p-2 rounded-full ${currentPage === index + 1 ? "text-red-500" : "text-gray-400"}`}
                        >
                            <FaCircle />
                        </button>
                    ))}
                </div>

                {/* Tombol Next */}
                <button
                    className={`px-4 py-2 rounded ${currentPage === totalPages ? "bg-gray-300 cursor-not-allowed" : "bg-red-500 text-white"}`}
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                >
                    <FaArrowRight />
                </button>
            </div>
        </div>
    );
}

export default AllBerita;
