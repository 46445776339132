import axios from "axios";
import React, { useEffect, useState } from "react";

const AbsensiList = () => {
  const [Absensi, setAbsensi] = useState([]);

  // Filter states
  const [filterTahun, setFilterTahun] = useState("");
  const [filterSemester, setFilterSemester] = useState("");
  const [filterKelas, setFilterKelas] = useState("");
  const [filterRombel, setFilterRombel] = useState("");

  useEffect(() => {
    const fetchAbsensi = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}api/absensi/getall`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        // Filter data absensi berdasarkan kelas, rombel, tahun, dan semester
        const absensifilter = response.data.filter(
          (s) =>
            s.kelas === filterKelas &&
            s.rombel === filterRombel &&
            s.tahun === filterTahun &&
            s.semester === filterSemester
        );

        // Inisialisasi map untuk menyimpan data absensi per siswa
        const siswaDataMap = new Map();

        // Iterasi melalui data absensi yang terfilter
        absensifilter.forEach((record) => {
          record.siswa.forEach((siswa) => {
            const {
              siswaId,
              namaSiswa,
              sakit = 0,
              izin = 0,
              tanpa_keterangan = 0,
            } = siswa;

            // Pastikan kita menggunakan siswaId._id untuk membandingkan
            const siswaIdValue = siswaId._id;

            // Jika siswa sudah ada di map, akumulasi data absensinya
            if (siswaDataMap.has(siswaIdValue)) {
              const currentData = siswaDataMap.get(siswaIdValue);
              siswaDataMap.set(siswaIdValue, {
                ...currentData,
                namaSiswa: currentData.namaSiswa, // Tetap menggunakan nama yang pertama kali muncul
                sakit: currentData.sakit + sakit,
                izin: currentData.izin + izin,
                tanpa_keterangan:
                  currentData.tanpa_keterangan + tanpa_keterangan,
              });
            } else {
              // Jika siswa belum ada di map, tambahkan siswa dengan data awal
              siswaDataMap.set(siswaIdValue, {
                siswaId: siswaIdValue,
                namaSiswa,
                kelas: record.kelas,
                rombel: record.rombel,
                tahun: record.tahun,
                semester: record.semester,
                sakit,
                izin,
                tanpa_keterangan,
              });
            }
          });
        });

        // Konversi map menjadi array
        const dataTotalAbsensi = Array.from(siswaDataMap.values());

        // Simpan hasil ke state
        setAbsensi(dataTotalAbsensi);
      } catch (error) {
        console.error("Error fetching absensi:", error.response?.data?.msg);
      }
    };

    fetchAbsensi();
  }, [filterKelas, filterRombel, filterSemester, filterTahun]);

  return (
    <div className=" flex flex-col justify-center">
      {/* Filter Inputs */}
      <div className="flex flex-col gap-4 md:flex-row md:gap-6 mb-5">
        <div className="flex flex-col">
          <label className="text-sm font-medium text-gray-700 mb-1">
            Tahun:
          </label>
          <input
            type="text"
            value={filterTahun}
            onChange={(e) => setFilterTahun(e.target.value)}
            placeholder="Masukkan tahun"
            className="px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          />
        </div>
        <div className="flex flex-col">
          <label className="text-sm font-medium text-gray-700 mb-1">
            Semester:
          </label>
          <input
            type="text"
            value={filterSemester}
            onChange={(e) => setFilterSemester(e.target.value)}
            placeholder="Masukkan semester"
            className="px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          />
        </div>
        <div className="flex flex-col">
          <label className="text-sm font-medium text-gray-700 mb-1">
            Kelas:
          </label>
          <select
            value={filterKelas}
            onChange={(e) => setFilterKelas(e.target.value)}
            className="px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          >
            <option value="">Pilih kelas</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
          </select>
        </div>
        <div className="flex flex-col">
          <label className="text-sm font-medium text-gray-700 mb-1">
            Rombel:
          </label>
          <select
            value={filterRombel}
            onChange={(e) => setFilterRombel(e.target.value)}
            className="px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          >
            <option value="">Pilih rombel</option>
            <option value="A">A</option>
            <option value="B">B</option>
            <option value="C">C</option>
          </select>
        </div>
      </div>

      {/* Absensi Table */}
      {Absensi.length === 0 ? (
        <p>Tidak ada data absensi.</p>
      ) : (
        <div className="overflow-auto max-h-96">
          <table className="table-auto border-collapse border border-gray-300 w-full text-left">
            <thead className="bg-gray-200">
              <tr>
                <th className="border border-gray-300 px-4 py-2">Nama</th>
                <th className="border border-gray-300 px-4 py-2">Sakit</th>
                <th className="border border-gray-300 px-4 py-2">Izin</th>
                <th className="border border-gray-300 px-4 py-2">
                  Tanpa Keterangan
                </th>
              </tr>
            </thead>
            <tbody>
              {Absensi.map((siswa) => (
                <tr key={siswa.namaSiswa} className="hover:bg-gray-100">
                  <td className="border border-gray-300 px-4 py-2">
                    {siswa.namaSiswa}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {siswa.sakit}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {siswa.izin}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {siswa.tanpa_keterangan}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default AbsensiList;
