import axios from "axios";
import { jwtDecode } from "jwt-decode";
import React, { useEffect, useState } from "react";

const JumlahNilai = ({ mapel }) => {
  const [nilai, setNilai] = useState([]);
  const [persen, setPersen] = useState(null);
  const [error, setError] = useState(null);
  const [filterOptions, setFilterOptions] = useState({
    tahun: "",
    kelas: "",
    rombel: "",
    semester: "",
  });
  const [dataTerakhir, setDataTerakhir] = useState(null);
  const [tabelVisible, setTabelVisible] = useState(false);

  const kelasOptions = ["1", "2", "3", "4", "5", "6"];
  const rombelOptions = ["A", "B"];
  const semesterOptions = ["1", "2"];

  useEffect(() => {
    const fetchNilai = async () => {
      try {
        const token = localStorage.getItem("token");
        const decoded = jwtDecode(token);
        const userId = decoded.user.id;

        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        const [nilaiResponse, persenResponse] = await Promise.all([
          axios.get(
            `${process.env.REACT_APP_API_URL}api/nilai/mapel/${mapel}/createdBy/${userId}`,
            config
          ),
          axios.get(
            `${process.env.REACT_APP_API_URL}api/persen/mapel/${mapel}/user/${userId}`,
            config
          ),
        ]);

        // Setelah mendapatkan respons dari API, filter nilai berdasarkan filterOptions
        const filteredNilaiResponse = nilaiResponse.data.filter(
          (item) =>
            (!filterOptions.tahun || item.tahun === filterOptions.tahun) &&
            (!filterOptions.kelas || item.kelas === filterOptions.kelas) &&
            (!filterOptions.rombel || item.rombel === filterOptions.rombel) &&
            (!filterOptions.semester ||
              item.semester === filterOptions.semester)
        );

        setNilai(filteredNilaiResponse);
        setPersen(persenResponse.data);
      } catch (error) {
        setError("Error fetching data: " + error.message);
      }
    };

    if (mapel) {
      fetchNilai();
    }
  }, [mapel, filterOptions]);

  const uniqueJenis = [...new Set(nilai.map((data) => data.jenis))];

  const consolidatedNilai = nilai.reduce((acc, data) => {
    data.nilai.forEach((nilaiItem) => {
      if (!acc[nilaiItem.studentId]) {
        acc[nilaiItem.studentId] = {
          studentId: nilaiItem.studentId,
          name: nilaiItem.name,
          nilai: {},
          kelas: data.kelas,
          rombel: data.rombel,
          tahun: data.tahun,
          semester: data.semester,
        };
      }
      if (!acc[nilaiItem.studentId].nilai[data.jenis]) {
        acc[nilaiItem.studentId].nilai[data.jenis] = [];
      }
      acc[nilaiItem.studentId].nilai[data.jenis].push(nilaiItem.nilaiTotal);
    });
    return acc;
  }, {});

  const calculateFinalScore = (averageNilai, jenis) => {
    if (!persen || !persen[jenis]) return 0;
    const percentage = persen[jenis] / 100;
    return averageNilai * percentage;
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilterOptions({ ...filterOptions, [name]: value });
  };

  const applyFilters = (data) => {
    const { tahun, kelas, rombel, semester } = filterOptions;

    // Pastikan tahun, kelas, rombel, dan semester tidak kosong sebelum memfilter
    if (!tahun || !kelas || !rombel || !semester) {
      return [];
    }

    const filteredData = data.filter((item) => {
      return (
        (!tahun || item.tahun === tahun) &&
        (!kelas || item.kelas === kelas) &&
        (!rombel || item.rombel === rombel) &&
        (!semester || item.semester === semester)
      );
    });

    return filteredData;
  };

  const filteredNilai = applyFilters(Object.values(consolidatedNilai));

  const handleSubmit = async () => {
    try {
      const token = localStorage.getItem("token");
      const decoded = jwtDecode(token);
      const userId = decoded.user.id;

      const nilaiFinalData = filteredNilai.map((student) => ({
        studentId: student.studentId,
        name: student.name,
        finalScore: uniqueJenis.reduce((total, jenis) => {
          const totalNilai = student.nilai[jenis]
            ? student.nilai[jenis].reduce((total, n) => total + n, 0)
            : 0;
          const countNilai = student.nilai[jenis]
            ? student.nilai[jenis].length
            : 0;
          const averageNilai = countNilai ? totalNilai / countNilai : 0;
          const finalScore = calculateFinalScore(averageNilai, jenis);
          return total + finalScore;
        }, 0),
      }));

      const data = {
        mapel,
        kelas: filterOptions.kelas,
        rombel: filterOptions.rombel,
        tahun: filterOptions.tahun,
        semester: filterOptions.semester,
        createdBy: userId,
        nilai: nilaiFinalData,
      };

      if (JSON.stringify(data) === JSON.stringify(dataTerakhir)) {
        alert("Data sudah pernah dikirim sebelumnya.");
        return;
      }

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      await axios.post(
        `${process.env.REACT_APP_API_URL}api/nilaiFinal`,
        data,
        config
      );
      setDataTerakhir(data);
      alert("Data berhasil dikirim");
    } catch (error) {
      alert(
        "Data telah tersedia, lakukan penghapusan data terlebih dahulu agar dapat memperbaharui data"
      );
    }
  };

  const handleDeleteData = async () => {
    try {
      const token = localStorage.getItem("token");
      const decoded = jwtDecode(token);
      const userId = decoded.user.id;

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const { tahun, kelas, rombel, semester } = filterOptions;

      await axios.delete(
        `${process.env.REACT_APP_API_URL}api/nilaiFinal/mapel/${mapel}/kelas/${kelas}/rombel/${rombel}/tahun/${tahun}/semester/${semester}/createdBy/${userId}`,
        config
      );

      setDataTerakhir(null);
      alert("Data berhasil dihapus");
    } catch (error) {
      alert("Error deleting data: " + error.message);
    }
  };

  // Memastikan bahwa tabel hanya muncul jika semua filter telah diisi
  useEffect(() => {
    if (
      filterOptions.tahun &&
      filterOptions.kelas &&
      filterOptions.rombel &&
      filterOptions.semester
    ) {
      setTabelVisible(true);
    } else {
      setTabelVisible(false);
    }
  }, [mapel, filterOptions]);

  return (
    <div className="container mx-auto p-4">
      <div className="grid mb-4 bg-white p-5 gap-y-4 rounded-lg drop-shadow-lg mx-3 md:mx-0">
        {/* Judul */}
        <h2 className="text-2xl font-bold mb-4 text-center">Nilai</h2>

        {/* Error Message */}
        {error && <p className="text-red-500 text-center">{error}</p>}

        {/* Filter Inputs */}
        <div className="grid gap-y-4">
          {/* Tahun */}
          <div className="flex items-center">
            <label className="w-1/4 font-medium">Tahun:</label>
            <input
              type="text"
              name="tahun"
              value={filterOptions.tahun}
              onChange={handleFilterChange}
              className="flex-1 border border-slate-500 rounded-lg px-4 py-2"
            />
          </div>

          {/* Kelas */}
          <div className="flex items-center">
            <label className="w-1/4 font-medium">Kelas:</label>
            <select
              name="kelas"
              value={filterOptions.kelas}
              onChange={handleFilterChange}
              className="flex-1 border border-slate-500 rounded-lg px-4 py-2"
            >
              <option value="">Pilih kelas</option>
              {kelasOptions.map((kelas, index) => (
                <option key={index} value={kelas}>
                  {kelas}
                </option>
              ))}
            </select>
          </div>

          {/* Rombel */}
          <div className="flex items-center">
            <label className="w-1/4 font-medium">Rombel:</label>
            <select
              name="rombel"
              value={filterOptions.rombel}
              onChange={handleFilterChange}
              className="flex-1 border border-slate-500 rounded-lg px-4 py-2"
            >
              <option value="">Pilih rombel</option>
              {rombelOptions.map((rombel, index) => (
                <option key={index} value={rombel}>
                  {rombel}
                </option>
              ))}
            </select>
          </div>

          {/* Semester */}
          <div className="flex items-center">
            <label className="w-1/4 font-medium">Semester:</label>
            <select
              name="semester"
              value={filterOptions.semester}
              onChange={handleFilterChange}
              className="flex-1 border border-slate-500 rounded-lg px-4 py-2"
            >
              <option value="">Pilih semester</option>
              {semesterOptions.map((semester, index) => (
                <option key={index} value={semester}>
                  {semester}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      <div className="overflow-x-auto overflow-y-auto max-w-[400px] max-h-[600px] sm:max-w-[600px] md:max-w-full md:max-h-[600px] shadow-lg  ">
        {tabelVisible && (
          <table className="min-w-full bg-white border border-gray-300  ">
            <thead className="bg-gray-200">
              <tr>
                <th className="py-2 px-4 border border-gray-300">Nama</th>
                {uniqueJenis.map((jenis, index) => (
                  <th key={index} className="py-2 px-4 border border-gray-300">
                    {jenis}
                  </th>
                ))}
                <th className="py-2 px-4 border border-gray-300">
                  Final Score
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredNilai.map((student, index) => (
                <tr key={index} className="hover:bg-gray-100">
                  <td className="py-2 px-4 border border-gray-300">
                    {student.name}
                  </td>
                  {uniqueJenis.map((jenis, jIndex) => {
                    const totalNilai = student.nilai[jenis]
                      ? student.nilai[jenis].reduce((total, n) => total + n, 0)
                      : 0;
                    const countNilai = student.nilai[jenis]
                      ? student.nilai[jenis].length
                      : 0;
                    const averageNilai = countNilai
                      ? totalNilai / countNilai
                      : 0;
                    const finalScore = calculateFinalScore(averageNilai, jenis);
                    return (
                      <td
                        key={jIndex}
                        className="py-2 px-4 border border-gray-300"
                      >
                        {student.nilai[jenis] ? (
                          <div className="flex flex-col">
                            {student.nilai[jenis].map((nilai, kIndex) => (
                              <span
                                key={kIndex}
                                className="border-b border-gray-300 py-1"
                              >
                                {nilai}
                              </span>
                            ))}
                            <span className="font-bold mt-2">
                              Total: {totalNilai} | Rata-rata:{" "}
                              {averageNilai.toFixed(2)} | Final:{" "}
                              {finalScore.toFixed(2)}
                            </span>
                          </div>
                        ) : (
                          ""
                        )}
                      </td>
                    );
                  })}
                  <td className="py-2 px-4 border border-gray-300">
                    {uniqueJenis
                      .reduce((total, jenis) => {
                        const totalNilai = student.nilai[jenis]
                          ? student.nilai[jenis].reduce(
                              (total, n) => total + n,
                              0
                            )
                          : 0;
                        const countNilai = student.nilai[jenis]
                          ? student.nilai[jenis].length
                          : 0;
                        const averageNilai = countNilai
                          ? totalNilai / countNilai
                          : 0;
                        const finalScore = calculateFinalScore(
                          averageNilai,
                          jenis
                        );
                        return total + finalScore;
                      }, 0)
                      .toFixed(2)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      {tabelVisible && (
        <button
          onClick={handleDeleteData}
          className="mt-4 py-2 px-4 bg-red-500 text-white rounded hover:bg-red-700"
        >
          Hapus Data
        </button>
      )}
      {tabelVisible && (
        <button
          onClick={handleSubmit}
          className="mt-4 ml-4 py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-700"
        >
          Submit
        </button>
      )}
    </div>
  );
};

export default JumlahNilai;
