import axios from "axios";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import Back from "../components/button/back";
import { storage } from "./FirebaseImage";

const AddEkskul = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    Judul: "",
    Deskripsi: "",
  });
  const [file, setFile] = useState(null);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!file) {
      console.error("File belum dipilih");
      return;
    }

    const fileName = `${uuidv4()}-${file.name}`;
    const storageRef = ref(storage, `ekskul/${fileName}`);

    try {
      // Upload file to Firebase Storage
      const uploadResult = await uploadBytes(storageRef, file);
      // Get download URL of the uploaded file
      const downloadURL = await getDownloadURL(uploadResult.ref);

      // Prepare data to send to backend API
      await axios.post(
        `${process.env.REACT_APP_API_URL}api/ekskul`,
        {
          Judul: formData.Judul,
          Deskripsi: formData.Deskripsi,
          Gambar: downloadURL, // Use download URL directly here
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      setFormData({
        Judul: "",
        Deskripsi: "",
      });
      setFile(null);
      navigate("/admin/ekstrakulikuler"); // Move navigate inside try block
    } catch (error) {
      console.error("Error uploading image or adding ekskul:", error);
    }
  };

  return (
    <div className="container mx-auto mt-10 px-4 sm:px-8">
      <h2 className="text-2xl font-bold mb-5 text-center sm:text-left">
        Add Ekskul
      </h2>
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="Judul"
          >
            Judul
          </label>
          <input
            type="text"
            name="Judul"
            value={formData.Judul}
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>

        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="Gambar"
          >
            Gambar
          </label>
          <input
            type="file"
            name="Gambar"
            onChange={handleFileChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>

        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="Deskripsi"
          >
            Deskripsi
          </label>
          <textarea
            name="Deskripsi"
            value={formData.Deskripsi}
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            rows="5"
            required
          ></textarea>
        </div>

        <div className="flex justify-center sm:justify-start">
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full sm:w-auto"
          >
            Submit
          </button>
        </div>
      </form>

      <div className="flex justify-center my-5">
        <Link to={"/admin/ekstrakulikuler"}>
          <Back />
        </Link>
      </div>
    </div>
  );
};

export default AddEkskul;
