// components/GuruDashboard.js
import React from "react";
import { Link } from "react-router-dom";

const subjects = [
  { name: "Matematika", path: "MTK" },
  { name: "IPA", path: "IPA" },
  { name: "IPS", path: "IPS" },
  { name: "IPAS", path: "IPAS" },
  { name: "Pendidikan Agama", path: "PAI" },
  { name: "Bahasa Indonesia", path: "BIndo" },
  { name: "Bahasa Inggris", path: "BIng" },
  { name: "Pendidikan Jasmani", path: "PJOK" },
  { name: "Senibudaya", path: "SENIBUDAYA" },
  { name: "MULOK", path: "MULOK" },
  { name: "PKN", path: "PKN" },
];


const GuruDashboard = () => {
  return (
    <div className="grid justify-center min-h-screen bg-blue-100">
      <div>
        <div className="flex justify-center items-center my-10">
          <h2 className="font-bold text-3xl">Dashboard Guru</h2>
        </div>
        <div className="flex justify-center items-center">
          <div className="grid grid-cols-2 gap-x-5 gap-y-10 md:grid-cols-3 md:gap-5">
            {subjects.map((subject) => (
              <div
                key={subject.path}
                className="bg-white drop-shadow-lg h-40 w-40 flex justify-center items-center font-bold rounded-lg hover:bg-slate-300 transform transition-transform duration-300 hover:scale-105"
              >
                <Link
                  to={`/guru/elearning-guru/${subject.path}`}
                  className="flex justify-center items-center text-center h-full w-full"
                >
                  <h1 className="text-2xl">{subject.name}</h1>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="my-10">
        
        
        {/* <div className="flex gap-5">
          <div>
            <Link to={`/profile`}>
              <div className="bg-white drop-shadow-lg h-40 w-40 flex justify-center items-center font-bold rounded-lg hover:bg-slate-300 transform transition-transform duration-300 hover:scale-105">
                <h1 className="text-2xl">Rapor</h1>
              </div>
            </Link>
          </div>
          <div>
            <Link to={"/listp5rapor"}>
              <div className="bg-white drop-shadow-lg h-40 w-40 flex justify-center items-center font-bold rounded-lg hover:bg-slate-300 transform transition-transform duration-300 hover:scale-105">
                <h1 className="text-2xl">Rapor P5</h1>
              </div>
            </Link>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default GuruDashboard;
