import axios from "axios";
import { jwtDecode } from "jwt-decode";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
const ProfileGuru = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [hasToken, setHasToken] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedSemester, setSelectedSemester] = useState("1");
  const [selectedYear, setSelectedYear] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          alert("Anda harus login untuk mengakses halaman ini.");
          navigate("/login");
          return;
        }

        const decoded = jwtDecode(token);
        if (!decoded) {
          alert("Token tidak valid. Silakan login kembali.");
          navigate("/login");
          return;
        }

        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}api/nilaiRapor/all`,
          config
        );

        const filtered = response.data.filter(
          (item) => item.kelas === user.kelas && item.rombel === user.rombel
        );
        setFilteredData(filtered);

        setLoading(false);
      } catch (err) {
        setError(err.response?.data?.message || "Terjadi kesalahan");
        setLoading(false);
      }
    };

    fetchData();
  }, [user.kelas, user.rombel, navigate]);
  useEffect(() => {
    const token = localStorage.getItem("token");
    setHasToken(!!token);

    if (token) {
      axios
        .get(`${process.env.REACT_APP_API_URL}api/auth/profile`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setUser(response.data);
        })
        .catch((error) => {
          console.error("Error fetching user profile:", error);
        });
    }
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  const filteredBySemesterAndYear = filteredData.filter(
    (item) =>
      item.semester === selectedSemester &&
      (selectedYear ? item.tahun === selectedYear : true)
  );

  const groupedData = filteredBySemesterAndYear.reduce((acc, item) => {
    const { nilai, mapel, tahun, semester } = item;
    nilai.forEach((student) => {
      if (!acc[student.name]) {
        acc[student.name] = {};
      }

      if (!acc[student.name][mapel]) {
        acc[student.name][mapel] = {};
      }

      acc[student.name][mapel][tahun] = acc[student.name][mapel][tahun] || {};
      acc[student.name][mapel][tahun][semester] = student.finalScore || "-";
    });
    return acc;
  }, {});

  const allYears = Array.from(
    new Set(filteredData.map((item) => item.tahun))
  ).sort();
  const semesters = ["1", "2"];
  const mapels = Array.from(new Set(filteredData.map((item) => item.mapel)));

  const rankingData = Object.entries(groupedData)
    .map(([studentName, subjects]) => {
      let totalScore = 0;
      let totalSubjects = 0;

      allYears.forEach((year) => {
        mapels.forEach((mapel) => {
          const score =
            parseFloat(subjects[mapel]?.[year]?.[selectedSemester]) || 0;
          if (score) {
            totalScore += score;
            totalSubjects++;
          }
        });
      });

      const averageScore =
        totalSubjects > 0 ? (totalScore / totalSubjects).toFixed(2) : 0;
      return { studentName, averageScore: parseFloat(averageScore) };
    })
    .sort((a, b) => b.averageScore - a.averageScore);

  // Add rank to each student based on their average score
  rankingData.forEach((student, index) => {
    student.rank = index + 1;
  });

  return (
    <div className="flex flex-col items-center justify-center">
      {/* Filter Tahun Ajaran dan Semester */}
      <div className="flex justify-center my-10">
        <h2 className="font-bold text-3xl">Daftar Nilai</h2>
      </div>
      <div className="w-full max-w-lg mb-6">
        <div className="flex mb-4 justify-center">
          <label htmlFor="year" className="p-2">
            Pilih Tahun Ajaran:
          </label>
          <select
            id="year"
            value={selectedYear}
            onChange={(e) => setSelectedYear(e.target.value)}
            className="p-2 border rounded"
          >
            <option value="">Semua Tahun</option>
            {allYears.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>

        <div className="flex mb-4 justify-center">
          <label htmlFor="semester" className="p-2">
            Pilih Semester:
          </label>
          <select
            id="semester"
            value={selectedSemester}
            onChange={(e) => setSelectedSemester(e.target.value)}
            className="p-2 border rounded"
          >
            {semesters.map((sem) => (
              <option key={sem} value={sem}>
                Semester {sem}
              </option>
            ))}
          </select>
        </div>
      </div>

      {/* Tabel Utama */}
      {filteredBySemesterAndYear.length === 0 ? (
        <div className="text-center mt-4 text-xl">Data belum ada</div>
      ) : (
        <div className="w-full overflow-auto">
          <table className="table-auto mx-auto border-collapse border border-gray-300">
            <thead>
              <tr className="bg-gray-200">
                <th className="px-6 py-3 border border-gray-300" rowSpan="2">
                  Siswa
                </th>
                {allYears.map((year) => (
                  <th
                    key={year}
                    className="px-6 py-3 border border-gray-300"
                    colSpan={mapels.length}
                  >
                    {year}
                  </th>
                ))}
                <th className="px-6 py-3 border border-gray-300" rowSpan="2">
                  Total
                </th>
                <th className="px-6 py-3 border border-gray-300" rowSpan="2">
                  Peringkat
                </th>
              </tr>
              <tr className="bg-gray-100">
                {allYears.map((year) =>
                  mapels.map((mapel) => (
                    <th
                      key={`${year}-${mapel}`}
                      className="px-6 py-3 border border-gray-300"
                    >
                      {mapel}
                    </th>
                  ))
                )}
              </tr>
            </thead>
            <tbody>
              {Object.entries(groupedData).map(([studentName, subjects]) => {
                let totalScore = 0;
                let totalSubjects = 0;

                const rowData = allYears.map((year) =>
                  mapels.map((mapel) => {
                    const score =
                      parseFloat(subjects[mapel]?.[year]?.[selectedSemester]) ||
                      0;
                    if (score) {
                      totalScore += score;
                      totalSubjects++;
                    }
                    return (
                      <td
                        key={`${year}-${mapel}`}
                        className="px-6 py-3 border border-gray-300"
                      >
                        {score || "-"}
                      </td>
                    );
                  })
                );

                const averageScore =
                  totalSubjects > 0
                    ? (totalScore / totalSubjects).toFixed(2)
                    : "-";

                return (
                  <tr key={studentName} className="hover:bg-gray-50">
                    <td className="px-6 py-3 border border-gray-300">
                      {studentName}
                    </td>
                    {rowData}
                    <td className="px-6 py-3 border border-gray-300">
                      {averageScore}
                    </td>
                    <td className="px-6 py-3 border border-gray-300">
                      {
                        rankingData.find(
                          (rank) => rank.studentName === studentName
                        )?.rank
                      }
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}

      <div className="pt-10">
        <div className="flex justify-center my-10">
          <h2 className="font-bold text-3xl">Absensi dan Rapor</h2>
        </div>
        <div className="flex gap-5">
          <div>
            <Link to={`/absensi/${id}`}>
              <div className="bg-white drop-shadow-lg h-40 w-40 flex justify-center items-center font-bold rounded-lg hover:bg-slate-300 transform transition-transform duration-300 hover:scale-105">
                <h1 className="text-2xl">Absensi</h1>
              </div>
            </Link>
          </div>
          <div>
            <Link to={"/guru/rapor"}>
              <div className="bg-white drop-shadow-lg h-40 w-40 flex justify-center items-center font-bold rounded-lg hover:bg-slate-300 transform transition-transform duration-300 hover:scale-105">
                <h1 className="text-2xl">Rapor</h1>
              </div>
            </Link>
          </div>
          <div>
            <Link to={"/listp5rapor"}>
              <div className="bg-white drop-shadow-lg h-40 w-40 flex justify-center items-center font-bold rounded-lg hover:bg-slate-300 transform transition-transform duration-300 hover:scale-105">
                <h1 className="text-2xl">Rapor P5</h1>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileGuru;
