import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Back from "../../components/button/back";
import TotalAbsen from "../Absen/TotalAbsen";

const Absensi = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [siswa, setSiswa] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [tahun, setTahun] = useState("");
  const [semester, setSemester] = useState("");
  const [tanggal, setTanggal] = useState("");
  const [kelas, setKelas] = useState("");
  const [rombel, setRombel] = useState("");
  const [attendance, setAttendance] = useState({});

  useEffect(() => {
    const currentDate = new Date();
    setTahun(currentDate.getFullYear());
    setTanggal(currentDate.toISOString().split("T")[0]);
  }, []);

  useEffect(() => {
    const fetchSiswa = async () => {
      if (!kelas || !rombel) return;

      setLoading(true);
      setError(null);

      try {
        const token = localStorage.getItem("token");
        if (!token) throw new Error("Silakan login terlebih dahulu.");

        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}api/auth/users`,
          {
            headers: { Authorization: `Bearer ${token}` },
            params: { tahun, semester, kelas, rombel },
          }
        );

        const siswaData = response.data.filter((s) => s.role === "siswa");
        const filterkelasrombel = siswaData.filter(
          (s) => s.kelas === kelas && s.rombel === rombel
        );
        setSiswa(filterkelasrombel);
      } catch (err) {
        setError(err.response?.data?.msg || err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchSiswa();
  }, [tahun, semester, kelas, rombel]);

  const handleAttendanceChange = (studentId, type, value) => {
    setAttendance((prev) => ({
      ...prev,
      [studentId]: {
        ...prev[studentId],
        [type]: value,
      },
    }));
  };

  const handleSubmit = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) throw new Error("Silakan login terlebih dahulu.");

      const absensiData = siswa.map((s) => ({
        siswaId: s._id,
        namaSiswa: s.name,
        sakit: attendance[s._id]?.sakit || 0,
        izin: attendance[s._id]?.izin || 0,
        tanpa_keterangan: attendance[s._id]?.tanpa_keterangan || 0,
      }));

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}api/absensi`,
        { tahun, semester, tanggal, kelas, rombel, siswa: absensiData },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.data.message === "Data sudah ada") {
        alert("Data absensi dengan tanggal, kelas, dan rombel ini sudah ada.");
      } else {
        alert("Absensi berhasil disimpan!");
      }
    } catch (err) {
      alert(err.response?.data?.msg || "Gagal menyimpan absensi.");
    }
  };

  if (loading) return <div className="text-center">Loading...</div>;
  if (error)
    return <div className="text-red-500 text-center">Error: {error}</div>;

  return (
    <div className="min-h-screen p-4 bg-gray-100">
      <h1 className="text-2xl font-bold text-center mb-6">
        Buat Absen Hari Ini
      </h1>
      <div className="flex flex-wrap gap-4 justify-center mb-6">
        <label className="flex flex-col w-full sm:w-1/2 md:w-1/4">
          <span className="font-medium">Tahun:</span>
          <input
            type="text"
            name="tahun"
            value={tahun}
            onChange={(e) => setTahun(e.target.value)}
            className="border rounded p-2"
          />
        </label>
        <label className="flex flex-col w-full sm:w-1/2 md:w-1/4">
          <span className="font-medium">Semester:</span>
          <select
            name="semester"
            value={semester}
            onChange={(e) => setSemester(e.target.value)}
            className="border rounded p-2"
          >
            <option value="">Pilih Semester</option>
            <option value="1">1</option>
            <option value="2">2</option>
          </select>
        </label>
        <label className="flex flex-col w-full sm:w-1/2 md:w-1/4">
          <span className="font-medium">Tanggal:</span>
          <input
            type="date"
            name="tanggal"
            value={tanggal}
            onChange={(e) => setTanggal(e.target.value)}
            className="border rounded p-2"
          />
        </label>
        <label className="flex flex-col w-full sm:w-1/2 md:w-1/4">
          <span className="font-medium">Kelas:</span>
          <select
            name="kelas"
            value={kelas}
            onChange={(e) => setKelas(e.target.value)}
            className="border rounded p-2"
          >
            <option value="">Pilih Kelas</option>
            {["1", "2", "3", "4", "5", "6"].map((kelas) => (
              <option key={kelas} value={kelas}>
                {kelas}
              </option>
            ))}
          </select>
        </label>
        <label className="flex flex-col w-full sm:w-1/2 md:w-1/4">
          <span className="font-medium">Rombel:</span>
          <select
            name="rombel"
            value={rombel}
            onChange={(e) => setRombel(e.target.value)}
            className="border rounded p-2"
          >
            <option value="">Pilih Rombel</option>
            {["A", "B", "C"].map((rombel) => (
              <option key={rombel} value={rombel}>
                {rombel}
              </option>
            ))}
          </select>
        </label>
      </div>

      <div className="overflow-auto border rounded-lg">
        <table className="w-full table-auto text-left bg-white">
          <thead>
            <tr className="bg-gray-200">
              <th className="p-2">Nama</th>
              <th className="p-2">Sakit</th>
              <th className="p-2">Izin</th>
              <th className="p-2">Tanpa Keterangan</th>
            </tr>
          </thead>
          <tbody>
            {siswa.map((s) => (
              <tr key={s._id} className="odd:bg-gray-50 even:bg-gray-100">
                <td className="p-2">{s.name}</td>
                <td className="p-2">
                  <select
                    value={attendance[s._id]?.sakit || 0}
                    onChange={(e) =>
                      handleAttendanceChange(
                        s._id,
                        "sakit",
                        parseInt(e.target.value)
                      )
                    }
                    className="border rounded p-1"
                  >
                    {[0, 1].map((val) => (
                      <option key={val} value={val}>
                        {val}
                      </option>
                    ))}
                  </select>
                </td>
                <td className="p-2">
                  <select
                    value={attendance[s._id]?.izin || 0}
                    onChange={(e) =>
                      handleAttendanceChange(
                        s._id,
                        "izin",
                        parseInt(e.target.value)
                      )
                    }
                    className="border rounded p-1"
                  >
                    {[0, 1].map((val) => (
                      <option key={val} value={val}>
                        {val}
                      </option>
                    ))}
                  </select>
                </td>
                <td className="p-2">
                  <select
                    value={attendance[s._id]?.tanpa_keterangan || 0}
                    onChange={(e) =>
                      handleAttendanceChange(
                        s._id,
                        "tanpa_keterangan",
                        parseInt(e.target.value)
                      )
                    }
                    className="border rounded p-1"
                  >
                    {[0, 1].map((val) => (
                      <option key={val} value={val}>
                        {val}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="mt-4 flex justify-center">
        <button
          onClick={handleSubmit}
          className="bg-blue-500 text-white px-4 py-2 rounded shadow hover:bg-blue-600"
        >
          Simpan Absensi
        </button>
      </div>

      <div className="pt-10">
        <div className="flex justify-center">
          <h1 className="font-bold text-xl">Daftar absen akhir</h1>
        </div>
        <div className="flex justify-center">
          <TotalAbsen />
        </div>
      </div>
      <div className="flex justify-center py-5">
        <button
          onClick={() => navigate(`/absensi/edit/${id}`)}
          className="bg-red-500 text-white font-semibold py-2 px-4 rounded-lg shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300"
        >
          Edit Absensi
        </button>
      </div>

      <div className="pt-5" onClick={() => navigate(`/profile/${id}`)}>
        <Back />
      </div>
    </div>
  );
};

export default Absensi;
