import axios from "axios";
import React, { useEffect, useState } from "react";
import { FiEdit } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import { Link } from "react-router-dom";
import Back from "../components/button/back";

const ListSiswa = () => {
  const [siswas, setSiswas] = useState([]);
  const [kelas, setKelas] = useState("");
  const [rombel, setRombel] = useState("");
  const [name, setName] = useState("");

  useEffect(() => {
    const fetchSiswas = async () => {
      try {
        const token = localStorage.getItem("token");

        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}api/auth/users`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setSiswas(response.data.filter((user) => user.role === "siswa"));
      } catch (error) {
        console.error("Error fetching siswas:", error);
      }
    };

    fetchSiswas();
  }, []);

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this user?"
    );
    if (!confirmDelete) return;

    try {
      const token = localStorage.getItem("token");
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}api/auth/users/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200 || response.status === 204) {
        setSiswas(siswas.filter((user) => user._id !== id)); // Menggunakan properti '_id'
        alert("User deleted successfully");
      } else {
        console.error("Error deleting user:", response.status);
      }
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  const handleNaikSemuaKelas = async () => {
    const confirmNaikKelas = window.confirm(
      "Apakah Anda yakin ingin menaikkan kelas semua siswa?"
    );
    if (!confirmNaikKelas) return;

    try {
      const token = localStorage.getItem("token");

      const updatePromises = siswas.map((user) => {
        let newKelas;
        switch (user.kelas) {
          case "1":
            newKelas = "2";
            break;
          case "2":
            newKelas = "3";
            break;
          case "3":
            newKelas = "4";
            break;
          case "4":
            newKelas = "5";
            break;
          case "5":
            newKelas = "6";
            break;
          case "6":
            newKelas = "lulus";
            break;
          default:
            newKelas = user.kelas;
        }

        return axios.put(
          `${process.env.REACT_APP_API_URL}api/auth/users/${user._id}`,
          { kelas: newKelas },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      });

      await Promise.all(updatePromises);

      const updatedSiswas = await axios.get(
        `${process.env.REACT_APP_API_URL}api/auth/users`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setSiswas(updatedSiswas.data.filter((user) => user.role === "siswa"));
      alert("Semua siswa berhasil dinaikkan kelasnya");
    } catch (error) {
      console.error("Error updating user class:", error);
      alert("Gagal menaikkan kelas semua siswa");
    }
  };

  const handleTurunSemuaKelas = async () => {
    const confirmTurunKelas = window.confirm(
      "Apakah Anda yakin ingin menurunkan kelas semua siswa?"
    );
    if (!confirmTurunKelas) return;

    try {
      const token = localStorage.getItem("token");

      const updatePromises = siswas.map((user) => {
        let newKelas;
        switch (user.kelas) {
          case "2":
            newKelas = "1";
            break;
          case "3":
            newKelas = "2";
            break;
          case "4":
            newKelas = "3";
            break;
          case "5":
            newKelas = "4";
            break;
          case "6":
            newKelas = "5";
            break;
          case "lulus":
            newKelas = "6";
            break;
          default:
            newKelas = user.kelas;
        }

        return axios.put(
          `${process.env.REACT_APP_API_URL}api/auth/users/${user._id}`,
          { kelas: newKelas },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      });

      await Promise.all(updatePromises);

      const updatedSiswas = await axios.get(
        `${process.env.REACT_APP_API_URL}api/auth/users`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setSiswas(updatedSiswas.data.filter((user) => user.role === "siswa"));
      alert("Semua siswa berhasil diturunkan kelasnya");
    } catch (error) {
      console.error("Error updating user class:", error);
      alert("Gagal menurunkan kelas semua siswa");
    }
  };

  const filteredSiswas = siswas.filter(
    (user) =>
      (kelas === "" || user.kelas === kelas) &&
      (rombel === "" || user.rombel === rombel) &&
      (name === "" || user.name.toLowerCase().includes(name.toLowerCase()))
  );

  return (
    <div className="min-h-screen bg-blue-100">
      <div className="px-5 py-5">
        <div className="flex justify-center">
          <h1 className="text-3xl font-bold text-gray-800">Data Siswa</h1>
        </div>

        <div className="my-10 flex flex-col sm:flex-row justify-between space-y-4 sm:space-y-0 sm:space-x-4">
          {/* Button untuk menambah pengguna */}
          <Link
            to={"/admin/add-user"}
            className="bg-blue-800 text-white px-5 py-3 rounded-full hover:bg-blue-600 font-bold transition duration-300 text-center w-full sm:w-auto"
          >
            Tambah Pengguna
          </Link>

          {/* Tombol Naikkan dan Turunkan Semua Kelas */}
          <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4 w-full sm:w-auto">
            <button
              onClick={handleNaikSemuaKelas}
              className="bg-green-800 text-white px-5 py-3 rounded-full hover:bg-green-600 font-bold transition duration-300 w-full sm:w-auto"
            >
              Naikkan Semua Kelas
            </button>
            <button
              onClick={handleTurunSemuaKelas}
              className="bg-red-800 text-white px-5 py-3 rounded-full hover:bg-red-600 font-bold transition duration-300 w-full sm:w-auto"
            >
              Turunkan Semua Kelas
            </button>
          </div>
        </div>

        <div className="flex flex-col sm:flex-row justify-between mb-4 space-y-4 sm:space-y-0 sm:space-x-4">
          {/* Input untuk mencari nama */}
          <input
            type="text"
            placeholder="Cari Nama"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="px-4 py-2 rounded-lg border border-gray-300 w-full sm:w-auto"
          />

          {/* Dropdown untuk memilih kelas */}
          <select
            value={kelas}
            onChange={(e) => setKelas(e.target.value)}
            className="px-4 py-2 rounded-lg border border-gray-300 w-full sm:w-auto"
          >
            <option value="">Pilih Kelas</option>
            {[1, 2, 3, 4, 5, 6, "lulus"].map((kelasOption) => (
              <option key={kelasOption} value={kelasOption}>
                {kelasOption}
              </option>
            ))}
          </select>

          {/* Dropdown untuk memilih rombel */}
          <select
            value={rombel}
            onChange={(e) => setRombel(e.target.value)}
            className="px-4 py-2 rounded-lg border border-gray-300 w-full sm:w-auto"
          >
            <option value="">Pilih Rombel</option>
            {["A", "B"].map((rombelOption) => (
              <option key={rombelOption} value={rombelOption}>
                {rombelOption}
              </option>
            ))}
          </select>
        </div>

        <div className="overflow-x-auto shadow-md sm:rounded-lg">
          <table className="min-w-full divide-y divide-gray-200 bg-white">
            <thead className="bg-gradient-to-tr from-cyan-600 to-cyan-400">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                  NO
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                  Foto
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                  Nama
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                  NISN
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                  NIPD
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                  Kelamin
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                  Tempat Lahir
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                  Tanggal Lahir
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                  Kelas
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                  Rombel
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                  Edit
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                  Hapus
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {filteredSiswas.map((user, index) => (
                <tr
                  key={user._id}
                  className="hover:bg-gray-100 transition duration-150"
                >
                  <td className="px-6 py-4 whitespace-nowrap">{index + 1}</td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <img
                      src={user.image}
                      alt={`foto ${user.name}`}
                      className="w-10 h-10 rounded-full object-cover"
                    />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">{user.name}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{user.NISN}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{user.NIPD}</td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {user.kelamin}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">{user.TL}</td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {new Date(user.TGL).toLocaleDateString()}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">{user.kelas}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{user.rombel}</td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <Link
                      to={`/admin/edit-user/${user._id}`}
                      className="text-blue-500 hover:text-blue-700"
                    >
                      <FiEdit />
                    </Link>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <MdDelete
                      onClick={() => handleDelete(user._id)}
                      className="text-red-500 hover:text-red-700 cursor-pointer"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="mx-5">
        <Link to={"/admin"}>
          <Back />
        </Link>
      </div>
    </div>
  );
};

export default ListSiswa;
