import axios from "axios";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import React, { useEffect, useState } from "react";
import { FaCheck } from "react-icons/fa";
import { useParams } from "react-router-dom";
import KertasA4 from "./KertasA4";

const ViewRapor = () => {
  const { id, di, sid, tahun, semester, kelas, rombel } = useParams();
  const [raporDetails, setRaporDetails] = useState(null);
  const [raporDetailItem, setRaporDetailItem] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [nilais, setNilais] = useState([]);
  const [loading, setLoading] = useState(true);
  const [datap5, setDataP5] = useState([]);
  const [nmapel, setNmapel] = useState();

  const pages = [];
  const ITEMS_PER_PAGE = 4; // Tentukan jumlah maksimal baris per halaman  const ITEMS_PER_PAGE = 4; // Tentukan jumlah maksimal baris per halaman
  // Bagi data ke dalam kelompok halaman
  for (let i = 0; i < nilais.length; i += ITEMS_PER_PAGE) {
    pages.push(nilais.slice(i, i + ITEMS_PER_PAGE));
  }

  useEffect(() => {
    const fetchRaporDetails = async () => {
      try {
        const token = localStorage.getItem("token");
        const raporUrl = `${process.env.REACT_APP_API_URL}api/dataRapor/getbyid/${id}`;

        const [raporResponse] = await Promise.all([
          axios.get(raporUrl, {
            headers: { Authorization: `Bearer ${token}` },
          }),
        ]);

        const raporData = { ...raporResponse.data };

        const dataSiswa = raporData.dataKeterangan.filter(
          (items) => items.studentId === sid
        );
        const dataNilai = raporData.dataNilai.filter(
          (items) => items.studentId === sid
        );
        const NilaiSaja = dataNilai.flatMap(item => item.nilai);

        // Update state nilai
        const banyakMapel = dataNilai.flatMap(
          (item) => item.nilai.map((nilaiItem) => nilaiItem.mapel).length
        );
        setNmapel(banyakMapel); // Ini akan menghasilkan array berisi semua mapel
        setNilais(NilaiSaja);

        setNilais(NilaiSaja);

        setRaporDetails(raporData);
        setUserDetails(dataSiswa);

        const filteredData = raporData.dataKeterangan.filter(
          (item) => item._id === di
        );
        setRaporDetailItem(filteredData.length > 0 ? filteredData[0] : null);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching rapor details:", error);
        setLoading(false);
      }
    };

    fetchRaporDetails();
  }, [id, di, sid, tahun, semester, kelas, rombel]);

  useEffect(() => {
    const fetchRaporDetails = async () => {
      try {
        const token = localStorage.getItem("token");
        const url = `${process.env.REACT_APP_API_URL}api/p5rapor/all`;
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const filter = response.data.filter((user) => {
          return (
            user.tahun === tahun &&
            user.semester === semester &&
            user.kelas === kelas
          );
        });
        setDataP5(filter);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };
    fetchRaporDetails();
  }, [kelas, semester, tahun]);

  const getSemesterText = (semester) => {
    switch (semester) {
      case "1":
        return "Satu";
      case "2":
        return "Dua";
      default:
        return "";
    }
  };
  const getTahunText = (semester) => {
    switch (semester) {
      case "1":
        return `${raporDetails.tahun} / ${parseInt(raporDetails.tahun) + 1}`;
      case "2":
        return `${parseInt(raporDetails.tahun) - 1} / ${raporDetails.tahun}`;
      default:
        return "";
    }
  };

  const getkelasText = (kelas) => {
    switch (kelas) {
      case "1":
        return "A";
      case "2":
        return "A";
      case "3":
        return "B";
      case "4":
        return "B";
      case "5":
        return "C";
      case "6":
        return "C";
      default:
        return "";
    }
  };

  const subjectMapping = {
    PAI: "Pendidikan Agama dan Budi Pekerti",
    PKN: "Pendidikan Pancasila",
    BIndo: "Bahasa Indonesia",
    MTK: "Matematika",
    IPA: "Ilmu Pengetahuan Alam",
    IPS: "Ilmu Pengetahuan Sosial",
    IPAS: "Ilmu Pengetahuan Alam dan Sosial",
    PJOK: "Pendidikan Jasmani Olahraga dan Kesehatan",
    SENIBUDAYA: "Seni dan Budaya",
    BIng: "Bahasa Inggris",
    MULOK: "Muatan Lokal",
  };

  const subjectOrder = [
    "PAI",
    "PKN",
    "BIndo",
    "MTK",
    "IPA",
    "IPS",
    "IPAS",
    "PJOK",
    "Senibudaya",
    "BIng",
    "MULOK",
  ];

  const getMappedSubjectName = (subjectCode) => {
    return subjectMapping[subjectCode] || subjectCode;
  };

  const sortNilaisBySubjectOrder = (nilais) => {
    return nilais.sort((a, b) => {
      return subjectOrder.indexOf(a.mapel) - subjectOrder.indexOf(b.mapel);
    });
  };

  const handlePrint = async () => {
    const ids = ["rapor-1", "rapor1", "rapor2"]; // ID elemen yang akan dicetak
    const pdf = new jsPDF("p", "mm", "a4");
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();

    for (let i = 0; i < ids.length; i++) {
      const id = ids[i];
      const input = document.getElementById(id);

      if (!input) {
        console.error(`Element with id '${id}' not found`);
        continue;
      }

      // Render elemen ke canvas
      const canvas = await html2canvas(input, {
        scale: 3, // Meningkatkan kualitas hasil render
        useCORS: true, // Jika elemen memiliki gambar eksternal
      });

      const imgData = canvas.toDataURL("image/jpeg", 0.9); // Kualitas tinggi
      const imgWidth = pdfWidth;

      // Reducing the image height slightly by scaling
      const imgHeight = (canvas.height / canvas.width) * pdfWidth * 0.98; // Reducing by 5%

      // Jika tinggi gambar lebih besar dari halaman PDF, pecah menjadi beberapa halaman
      let position = 0;
      while (position < imgHeight) {
        pdf.addImage(imgData, "JPEG", 0, -position, imgWidth, imgHeight);
        position += pdfHeight;
        if (position < imgHeight) {
          pdf.addPage();
        }
      }

      // Tambahkan halaman baru jika bukan elemen terakhir
      if (i < ids.length - 1) {
        pdf.addPage();
      }
    }

    pdf.save(`rapor-${userDetails[0].name}.pdf`);
  };



  if (loading) {
    return <p>Loading...</p>;
  }

  if (!raporDetailItem || !userDetails) {
    return <p>No data available</p>;
  }

  return (
    <div className="relative min-h-screen bg-gray-100 flex items-center justify-center overflow-x-auto">
      <div className="flex-shrink-0 ">
        <div className="flex justify-center items-center bg-red mt-10 ">
          <button
            onClick={handlePrint}
            className="  px-4 py-2 bg-blue-500 text-white rounded "
          >
            Download PDF
          </button>
        </div>
        <div id="rapor-1">
          {pages.map((pageNilais, pageIndex) => (
            <KertasA4 key={pageIndex}>
              <div>
                {/* Header */}
                <header className="mt-3">
                  <div className="flex justify-center mb-3">
                    {pageIndex === 0 &&
                      <h1 className="text-[16px] font-arial">
                        Laporan Hasil Belajar (Rapor)
                      </h1>
                    }

                  </div>
                  <div className="flex justify-between text-[12px] border-b border-black pb-2">
                    <div className="flex justify-between gap-10 mr-32  w-[400px]">
                      <div>
                        <h2>Nama</h2>
                        <h2>NISN</h2>
                        <h2>Sekolah</h2>
                        <h2>Alamat</h2>
                      </div>
                      <div className="">
                        <h2>: {userDetails[0].name}</h2>
                        <h2>: {userDetails[0].nisn}</h2>
                        <h2>: {raporDetails.nama_sekolah}</h2>
                        <h2>: {raporDetails.alamat_sekolah}</h2>
                      </div>
                    </div>
                    <div className="flex justify-between gap-10 w-[300px]">
                      <div>
                        <h2>Kelas</h2>
                        <h2>Fase</h2>
                        <h2>Semester</h2>
                        <h2>Tahun Pembelajaran</h2>
                      </div>
                      <div>
                        <h2>
                          : KELAS {raporDetails.kelas}
                          {raporDetails.rombel}
                        </h2>
                        <h2>: {getkelasText(raporDetails.kelas)}</h2>
                        <h2>
                          : {raporDetails.semester} (
                          {getSemesterText(raporDetails.semester)})
                        </h2>
                        <h2>: {getTahunText(raporDetails.semester)}</h2>
                      </div>
                    </div>
                  </div>
                </header>

                {/* Main Content */}
                <main className="font-arial flex-grow">
                  <div className="mb-3">
                    {pageIndex === 0 &&
                      <h1 className="text-[12px] font-semibold">
                        A. NILAI DAN CAPAIAN KOMPETENSI
                      </h1>
                    }
                  </div>

                  {/* Menambahkan class 'table-page-break' pada tabel */}
                  <div className="h-[830px] w-auto font-arial  ">
                    <table className="border border-black ">
                      <thead>
                        <tr>
                          <th className="pb-5 font-semibold text-[12px] border-r border-black w-9">
                            No
                          </th>
                          <th className="pb-5 font-semibold text-[12px] border-r border-black w-52 px-4">
                            Mata Pelajaran
                          </th>
                          <th className="pb-5 font-semibold text-[12px] border-r border-black w-10 px-4">
                            Nilai Akhir
                          </th>
                          <th className="pb-5 font-semibold text-[12px] border-0 border-black w-[700px] px-4">
                            Capaian Kompetensi
                          </th>
                        </tr>
                      </thead>

                      <tbody className="text-[12px] ">
                        {/* {JSON.stringify(sortNilaisBySubjectOrder(pageNilais))} */}
                        {sortNilaisBySubjectOrder(pageNilais).map(
                          (user, subIndex) => (
                            <React.Fragment key={user.studentId}>
                              {/* Menampilkan JSON untuk debugging */}

                              <tr key={user._id}>
                                <td className="h-auto pb-2 pt-0 text-center border-r border-t border-black">
                                  {pageIndex * ITEMS_PER_PAGE + subIndex + 1}{" "}
                                  {/* Jika perlu menggunakan subIndex, bisa diganti dengan index dari map */}
                                </td>
                                <td className="h-auto pb-2 pt-0 border-r border-t border-black px-4">
                                  {getMappedSubjectName(user.mapel)}{" "}
                                  {/* mapel adalah field langsung dari user */}
                                </td>
                                <td className="h-auto pb-2 pt-0 border-r border-t border-black px-4 text-center">
                                  {user.finalScore}{" "}
                                  {/* finalScore adalah field langsung dari user */}
                                </td>
                                <td className="h-auto pb-0 pt-0 mt-0 mb-0 border-t border-black p-0">
                                  {user.CPtercapi &&
                                    user.CPtercapi.length > 0 && (
                                      <div className="pb-2 pt-0 mt-0 mb-0 border-b border-black">
                                        <h1 className="pl-1">
                                          Kompetensi{" "}
                                          {user.CPtercapi.map((cp, index) => (
                                            <span key={index}>{cp}</span>
                                          ))}{" "}
                                          telah tercapai
                                        </h1>
                                      </div>
                                    )}
                                  {user.CPditingkatkan &&
                                    user.CPditingkatkan.length > 0 && (
                                      <div className="pb-2 border-t border-black">
                                        <h1 className="pl-1">
                                          Perlu peningkatan dalam{" "}
                                          {user.CPditingkatkan.map(
                                            (cp, index) => (
                                              <span key={index}>{cp}</span>
                                            )
                                          )}
                                        </h1>
                                      </div>
                                    )}
                                </td>
                              </tr>
                            </React.Fragment>
                          )
                        )}
                      </tbody>
                    </table>
                  </div>
                </main>

                {/* Footer */}
                <footer className="text-center border-t border-black pt-1 mt-8 font-arial text-[9px] font-semibold">
                  <div className="flex justify-between">
                    <div className="flex gap-2">
                      <h1>
                        KELAS {raporDetails.kelas}
                        {raporDetails.rombel}
                      </h1>
                      <h1> | </h1>
                      <h1>{userDetails[0].name}</h1>
                      <h1> | </h1>
                      <h1>{userDetails[0].nipd}</h1>
                    </div>
                    <div className="flex gap-5">
                      <h1>Halaman</h1>
                      <h1>: {pageIndex + 1}</h1>
                    </div>
                  </div>
                </footer>
              </div>
            </KertasA4>
          ))}
        </div>
        <div id="rapor1">
          <KertasA4>
            <header className="relative">
              <div className="flex justify-between text-[12px] border-b border-black pb-2  ">
                <div className="flex justify-between gap-10 mr-32 w-[400px]">
                  <div>
                    <h2>Nama</h2>
                    <h2>NISN</h2>
                    <h2>Sekolah</h2>
                    <h2>Alamat</h2>
                  </div>
                  <div className="">
                    <h2>: {userDetails[0].name}</h2>
                    <h2>: {userDetails[0].nisn}</h2>
                    <h2>: {raporDetails.nama_sekolah}</h2>
                    <h2>: {raporDetails.alamat_sekolah}</h2>
                  </div>
                </div>
                <div className="flex justify-between gap-10  w-[300px]">
                  <div>
                    <h2>Kelas</h2>
                    <h2>Fase</h2>
                    <h2>Semester</h2>
                    <h2>Tahun Pembelajaran</h2>
                  </div>
                  <div>
                    <h2>
                      : KELAS {raporDetails.kelas}
                      {raporDetails.rombel}
                    </h2>
                    <h2>: {getkelasText(raporDetails.kelas)} </h2>
                    <h2>
                      : {raporDetails.semester} (
                      {getSemesterText(raporDetails.semester)})
                    </h2>
                    <h2>
                      : {getTahunText(raporDetails.semester)}
                    </h2>
                  </div>
                </div>

                <br />
              </div>
            </header>
            <br />
            <main className="font-arial flex-grow  h-[868px]">
              <div className="mb-1">
                <h1 className="text-[12px] font-semibold">
                  B. EKSTRAKURIKULER
                </h1>
              </div>
              <div className=" h-auto w-auto font-arial ">
                <table className="border border-black">
                  <thead>
                    <tr>
                      <th className="font-semibold pb-3 text-[12px]  border-black  w-9  ">
                        No
                      </th>
                      <th className="font-semibold pb-3 text-[12px] border-l border-black w-60 px-4">
                        Kegiatan Ekstrakurikuler
                      </th>
                      <th className="font-semibold pb-3 text-[12px] border-l border-black w-[460px] px-4 ">
                        Keterangan
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-[12px]">
                    {raporDetailItem.ekskul &&
                      raporDetailItem.ekskul.length > 0 ? (
                      raporDetailItem.ekskul.map((ekskul, index) => (
                        <>
                          <tr key={index}>
                            <td className="text-center pb-3  border-t border-black ">
                              {index + 1}
                            </td>
                            <td className="border-l pb-3 border-t border-black px-4">
                              {ekskul.ekskulName}
                            </td>
                            <td className="border-l pb-3 border-t border-black px-4">
                              {ekskul.ekskulKet}
                            </td>
                          </tr>
                        </>
                      ))
                    ) : (
                      <>
                        <tr>
                          <td className="text-center pb-3 border-t border-black">
                            1
                          </td>
                          <td className="border-l border-t pb-3 border-black px-4"></td>
                          <td className="border-l border-t pb-3 border-black px-4"></td>
                        </tr>
                        <tr>
                          <td className="text-center pb-3 border-t border-black ">
                            2
                          </td>
                          <td className="border-l border-t pb-3 border-black px-4"></td>
                          <td className="border-l border-t pb-3 border-black px-4"></td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="mt-3 mb-1">
                <h1 className="text-[12px]  font-semibold">
                  C. KETIDAKHADIRAN
                </h1>
              </div>
              <div className=" h-auto  font-arial text-[12px]">
                <table className="border border-black w-[370px]">
                  <thead>
                    <tr className="border-b  border-black ">
                      <td className="pl-7 pb-1 flex justify-between">
                        <div className=" w-1/2">
                          <h1>Sakit</h1>
                        </div>
                        <div className=" w-1/3  pb-1 flex justify-end">
                          <h1>:</h1>
                        </div>
                        <div className=" w-1/4  pb-1 flex justify-center">
                          <h1>{raporDetailItem.sakit}</h1>
                        </div>
                        <div className=" w-1/4 pb-1">
                          <h1>Hari</h1>
                        </div>
                      </td>
                    </tr>
                    <tr className="border-b pb-3 border-black ">
                      <td className="pl-7 pb-1 flex justify-between">
                        <div className=" w-1/2 pb-1">
                          <h1>Izin</h1>
                        </div>
                        <div className=" w-1/3 pb-1 flex justify-end">
                          <h1>:</h1>
                        </div>
                        <div className=" w-1/4 pb-1 flex justify-center">
                          <h1>{raporDetailItem.izin}</h1>
                        </div>
                        <div className=" w-1/4 pb-1">
                          <h1>Hari</h1>
                        </div>
                      </td>
                    </tr>
                    <tr className=" pb-3  ">
                      <td className="pl-7 pb-1 flex justify-between">
                        <div className=" w-1/2 pb-1">
                          <h1>Tanpa Keterangan</h1>
                        </div>
                        <div className=" w-1/3  pb-1 flex justify-end">
                          <h1>:</h1>
                        </div>
                        <div className=" w-1/4 pb-1 flex justify-center">
                          <h1>{raporDetailItem.tanpaKeterangan}</h1>
                        </div>
                        <div className=" w-1/4 pb-1">
                          <h1>Hari</h1>
                        </div>
                      </td>
                    </tr>
                  </thead>
                </table>
              </div>
              <div className="mt-3 mb-1">
                <h1 className="text-[12px] font-semibold">
                  D. CATATAN WALI KELAS
                </h1>
              </div>
              <div className=" h-auto w-auto font-arial text-[12px] ">
                <div className="border pb-3 border-black">
                  <h1 className="px-7  py-5 font-semibold">
                    {raporDetailItem.CatatanaWakel}
                  </h1>
                </div>
                {semester === "2" && (
                  <div className="border border-black mt-5 pl-10 pb-2">
                    <h1 className="font-semibold">Keputusan :</h1>
                    <h1>
                      Berdasarkan hasil yang dicapi pada semester 1 dan 2,
                      Pesertadidik ditetapkan :
                    </h1>
                    <h1 className="font-semibold">
                      ( Naik ke / Tinggal di kelas *)
                      <span className="font-normal pl-2">
                        : ........................ ( ......................... )
                      </span>
                    </h1>
                  </div>
                )}
              </div>
              <div className="flex justify-between mt-5 font-arial text-[12px] mx-2">
                <div className="">
                  <h1>Mengetahui</h1>
                  <h1>Orang Tua/Wali</h1>
                  <div className=" h-10"></div>
                  <h1>.........................</h1>
                </div>
                <div>
                  <div className="flex gap-1">
                    <h1>{raporDetails.kota},</h1>
                    <h1> {raporDetailItem.tanggal}</h1>
                    <h1>{raporDetailItem.bulan}</h1>
                    <h1>{tahun}</h1>
                  </div>
                  <h1>Wali Kelas</h1>
                  <div className=" h-10"></div>
                  <br />
                  <h1>{raporDetails.nama_wakel}</h1>
                  <h1>Nip.{raporDetails.nip_wakel}</h1>
                </div>
              </div>
              <div className="flex justify-center text-[12px]">
                <div>
                  <h1 className="flex justify-center">Mengetahui</h1>
                  <h1 className="flex justify-center">Kepala Sekolah</h1>
                  <div className="h-10"></div>
                  <h1 className="flex justify-center">Mengetahui</h1>
                  <h1 className="flex justify-center">Kepala Sekolah</h1>
                  <div className="h-10"></div>
                  <br />
                  <div className="relative mx-2">
                    <h1 className="text-center">{raporDetails.namakepsek}</h1>
                    <div className="absolute left-0 right-0 top-full border-t border-black mt-1"></div>
                  </div>
                  <h1 className="leading-tight flex justify-center">
                    Nip.{raporDetails.nip_kepsek}
                  </h1>
                </div>

              </div>
            </main>
            <footer className="text-center  border-t border-black pt-1 mt-8 font-arial text-[9px] font-semibold ">
              <div className="flex justify-between">
                <div className="flex gap-2">
                  <h1>
                    KELAS {raporDetails.kelas}
                    {raporDetails.rombel}
                  </h1>
                  <h1> | </h1>
                  <h1>{userDetails[0].name}</h1>
                  <h1> | </h1>
                  <h1>{userDetails[0].nipd}</h1>
                </div>
                <div className="flex gap-5">
                  <h1>Halaman</h1>
                  {nmapel > 4 ? <h1>: 4</h1> : <h1>: 3</h1>}
                </div>
              </div>
            </footer>
          </KertasA4>
        </div>
        <div id="rapor2">
          <KertasA4>
            <header className="relative ">
              <div className="flex justify-center mb-3">
                <h1 className="text-[16px]  font-arial">
                  RAPOR PROJEK PENGUATAN PROFIL PELAJAR PANCASILA
                </h1>
              </div>
              <div className="flex justify-between text-[12px] border-b border-black pb-2">
                <div className="flex justify-between gap-10 mr-32 w-[400px]">
                  <div>
                    <h2>Nama</h2>
                    <h2>NISN</h2>
                    <h2>Sekolah</h2>
                    <h2>Alamat</h2>
                  </div>
                  <div>
                    <h2>: {userDetails[0].name}</h2>
                    <h2>: {userDetails[0].nisn}</h2>
                    <h2>: {raporDetails.nama_sekolah}</h2>
                    <h2>: {raporDetails.alamat_sekolah}</h2>
                  </div>
                </div>
                <div className="flex justify-between gap-10 w-[300px]">
                  <div>
                    <h2>Kelas</h2>
                    <h2>Fase</h2>
                    <h2>Semester</h2>
                    <h2>Tahun Pembelajaran</h2>
                  </div>
                  <div>
                    <h2>
                      : KELAS {raporDetails.kelas}
                      {raporDetails.rombel}
                    </h2>
                    <h2>: {getkelasText(raporDetails.kelas)}</h2>
                    <h2>
                      : {raporDetails.semester} (
                      {getSemesterText(raporDetails.semester)})
                    </h2>
                    <h2>
                      :{" "}
                      {raporDetails.semester === "1"
                        ? `${raporDetails.tahun} / ${parseInt(raporDetails.tahun) + 1
                        }`
                        : `${parseInt(raporDetails.tahun) - 1} / ${raporDetails.tahun
                        } `}
                    </h2>
                  </div>
                </div>
              </div>
            </header>
            <main className=" w-auto h-[890px] font-arial text-[11px] pt-5">
              <div>
                {datap5.map((user, index) => (
                  <div key={user._id}>
                    <div className="border pb-3 border-black px-1">
                      <div className="font-bold text-[13px] pb-2 flex gap-2 pt-1">
                        <h1>Projek Profil{index + 1}</h1>
                        <h1>|</h1>
                        <h1>{user.namaproyek}</h1>
                      </div>
                      <div>
                        <h1>{user.deskripsiproyek}</h1>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="mt-5">
                {datap5.map((data, index) => (
                  <div key={data._id}>
                    <table className="border border-black w-full">
                      <thead>
                        <tr className="flex border-b border-black">
                          {/* <th className="flex w-10 pt-3">{index + 1}</th> */}
                          <th className="w-[440px] pl-5 flex pt-3">
                            {data.namaproyek}
                          </th>
                          <th className="flex border-l border-black w-14">
                            Mulai Berkem bang
                          </th>
                          <th className="flex border-l border-black w-14">
                            Sudah Berkem bang
                          </th>
                          <th className="flex pb-3 border-l border-black w-14">
                            Berkem bang Sesuai Harapan
                          </th>
                          <th className="flex border-l  border-black w-14">
                            Sangat Berkem bang
                          </th>
                        </tr>
                        <tr className="border-b border-black h-5"></tr>
                      </thead>
                      <tbody>
                        {data.data
                          .filter((data) => data.studentId === sid)
                          .map((data, index) => (
                            <tr key={data._id} className="grid">
                              {data.item.map((item) => (
                                <div
                                  key={item._id}
                                  className="flex border-b border-black w-full"
                                >
                                  <div className="grid w-[440px]">
                                    <td className=" pl-10 h-7 pt-1  font-bold border-b  border-black bg-slate-400">
                                      {item.element}
                                    </td>
                                    <td className="relative  pl-14 h-14 flex items-center ">
                                      <span className="absolute left-0 top-1/2 transform -translate-y-1/2 text-2xl leading-none pl-5">
                                        •
                                      </span>
                                      {item.subelement}
                                    </td>
                                  </div>
                                  <div className="">
                                    <div className=" border-b bg-slate-400 border-black h-7 ">
                                      <td className="border-l border-black w-14 h-10"></td>
                                      <td className="border-l border-black w-14 h-10"></td>
                                      <td className="border-l border-black w-14 h-10"></td>
                                      <td
                                        className="border-l border-black w-14
                                    h-10"
                                      ></td>
                                    </div>
                                    <div className="  h-10  flex">
                                      <td className="  flex items-center justify-center border-l h-14 w-14 border-black">
                                        {item.MB && (
                                          <FaCheck className="text-black" />
                                        )}
                                      </td>
                                      <td className="w-14  flex items-center justify-center border-l h-14 border-black">
                                        {item.SB && (
                                          <FaCheck className="text-black" />
                                        )}
                                      </td>
                                      <td className="w-14  flex items-center justify-center border-l h-14 border-black">
                                        {item.BSH && (
                                          <FaCheck className="text-black" />
                                        )}
                                      </td>
                                      <td className="w-14  flex items-center justify-center border-l h-14 border-black">
                                        {item.SGB && (
                                          <FaCheck className="text-black" />
                                        )}
                                      </td>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                ))}
              </div>
              <div>
                {datap5.map((user) => (
                  <div key={user._id}>
                    {user.data
                      .filter((data) => data.studentId === sid)
                      .map((item) => (
                        <div key={item._id}>
                          <div className="border  border-black mt-2 h-auto p-1">
                            <div className="">
                              <h1 className="font-bold">Catatan Proses :</h1>
                            </div>
                            <div>
                              <h1>{item.catatanProses}</h1>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                ))}
              </div>
              <div className="flex justify-between mt-2 font-arial text-[12px] mx-2">
                <div className="">
                  <h1>Mengetahui</h1>
                  <h1>Orang Tua/Wali</h1>
                  <div className=" h-10"></div>
                  <h1>.........................</h1>
                </div>
                <div>
                  <div className="flex gap-1">
                    <h1>{raporDetails.kota},</h1>
                    <h1> {raporDetailItem.tanggal}</h1>
                    <h1>{raporDetailItem.bulan}</h1>
                    <h1>{tahun}</h1>
                  </div>

                  <h1>Wali Kelas</h1>
                  <div className=" h-10"></div>
                  <br />
                  <h1>{raporDetails.nama_wakel}</h1>
                  <h1>Nip.{raporDetails.nip_wakel}</h1>
                </div>
              </div>
              <div className="flex justify-center text-[12px]">
                <div>
                  <h1 className="flex justify-center">Mengetahui</h1>
                  <h1 className="flex justify-center">Kepala Sekolah</h1>
                  <div className="h-10"></div>
                  <br />
                  <div className="relative mx-2">
                    <h1 className="text-center">{raporDetails.namakepsek}</h1>
                    <div className="absolute left-0 right-0 top-full border-t border-black mt-1"></div>
                  </div>
                  <h1 className="leading-tight flex justify-center">
                    Nip.{raporDetails.nip_kepsek}
                  </h1>
                </div>
              </div>
            </main>
            <footer className="text-center  border-t border-black pt-1 font-arial text-[9px] font-semibold ">
              <div className="flex justify-between">
                <div className="flex gap-2">
                  <h1>
                    KELAS {raporDetails.kelas}
                    {raporDetails.rombel}
                  </h1>
                  <h1> | </h1>
                  <h1>{userDetails[0].name}</h1>
                  <h1> | </h1>
                  <h1>{userDetails[0].nipd}</h1>
                </div>
                <div className="flex gap-5">
                  <h1>Halaman</h1>
                  {nmapel > 4 ? <h1>: 5</h1> : <h1>: 4</h1>}
                </div>
              </div>
            </footer>
          </KertasA4>
        </div>
      </div>
    </div>
  );
};

export default ViewRapor;
