import axios from "axios";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Back from "../button/back";

Modal.setAppElement("#root");

const RaporUserList = () => {
  const [raporData, setRaporData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  const [updatedData, setUpdatedData] = useState({
    name: "",
    sakit: 0,
    izin: 0,
    bulan: "",
    tangal: "",
    tanpaKeterangan: 0,
    ekskul: [],
    CatatanaWakel: "",
  });

  // State untuk filter
  const [tahunFilter, setTahunFilter] = useState("");
  const [semesterFilter, setSemesterFilter] = useState("");
  const [kelasFilter, setKelasFilter] = useState("");
  const [rombelFilter, setRombelFilter] = useState("");

  // State untuk menentukan apakah semua filter telah terisi atau belum
  const [allFiltersFilled, setAllFiltersFilled] = useState(false);

  const fetchRaporData = async () => {
    try {
      const token = localStorage.getItem("token");
      const url = `${process.env.REACT_APP_API_URL}api/dataRapor/getall`;

      // Setup parameter untuk filter
      const params = {};
      if (tahunFilter) params.tahun = tahunFilter;
      if (semesterFilter) params.semester = semesterFilter;
      if (kelasFilter) params.kelas = kelasFilter;
      if (rombelFilter) params.rombel = rombelFilter;

      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: params,
      });

      const filteredUsers = response.data.filter((user) => {
        return (
          (!kelasFilter || user.kelas === kelasFilter) &&
          (!rombelFilter || user.rombel === rombelFilter) &&
          (!tahunFilter || user.tahun === tahunFilter) &&
          (!semesterFilter || user.semester === semesterFilter)
        );
      });

      setRaporData(filteredUsers);

      setLoading(false);
      // Periksa apakah semua filter terisi atau tidak
      setAllFiltersFilled(
        tahunFilter !== "" &&
          semesterFilter !== "" &&
          kelasFilter !== "" &&
          rombelFilter !== ""
      );
    } catch (error) {
      setLoading(false);
      if (error.response) {
        toast.error(`Error: ${error.response.data.message}`);
      } else if (error.request) {
        toast.error("No response received from the server.");
      } else {
        toast.error(`Error setting up the request: ${error.message}`);
      }
      console.error("Error fetching rapor data:", error);
    }
  };

  useEffect(() => {
    fetchRaporData(); // Fetch data saat komponen pertama kali dimuat atau saat nilai filter berubah
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tahunFilter, semesterFilter, kelasFilter, rombelFilter]); // Masukkan fetchRaporData ke dalam dependency array

  const closeModal = () => {
    setModalIsOpen(false);
    setCurrentData(null);
    setUpdatedData({
      name: "",
      sakit: 0,
      izin: 0,
      tanpaKeterangan: 0,
      bulan: "",
      tanggal: "",
      ekskul: [],
      CatatanaWakel: "",
    });
  };

  const handleUpdate = async () => {
    try {
      const token = localStorage.getItem("token");
      const url = `${process.env.REACT_APP_API_URL}api/dataRapor/update/${currentData.parentId}`;

      const response = await axios.put(
        url,
        { dataKeterangan: updatedData },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setRaporData((prevData) =>
        prevData.map((dataKeterangan) =>
          dataKeterangan._id === currentData.parentId
            ? {
                ...dataKeterangan,
                dataKeterangan: dataKeterangan.dataKeterangan.map((item) =>
                  item._id === updatedData._id ? response.data : item
                ),
              }
            : dataKeterangan
        )
      );
      toast.success("Data updated successfully");
      closeModal();
    } catch (error) {
      if (error.response) {
        toast.error(`Error: ${error.response.data.message}`);
      } else if (error.request) {
        toast.error("No response received from the server.");
      } else {
        toast.error(`Error setting up the request: ${error.message}`);
      }
      console.error("Error updating data:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const [field, ekskulIndex] = name.split("-");

    setUpdatedData((prevData) => {
      const updated = { ...prevData };
      if (ekskulIndex !== undefined) {
        updated.ekskul[ekskulIndex] = {
          ...updated.ekskul[ekskulIndex],
          [field]: value,
        };
      } else {
        updated[field] = value;
      }
      return updated;
    });
  };

  const handleOpenModalByName = (_id) => {
    const dataToUpdate = raporData.find((data) =>
      data.dataKeterangan.some((item) => item._id === _id)
    );

    if (dataToUpdate) {
      const updatedData = dataToUpdate.dataKeterangan.find(
        (item) => item._id === _id
      );

      if (updatedData) {
        const { tahun, kelas, rombel, semester, _id: parentId } = dataToUpdate;

        const dataItem = {
          CatatanaWakel: updatedData.CatatanaWakel,
          ekskul: updatedData.ekskul.map((ekskulItem) => ({
            ekskulName: ekskulItem.ekskulName,
            ekskulKet: ekskulItem.ekskulKet,
          })),
          izin: updatedData.izin,
          name: updatedData.name,
          sakit: updatedData.sakit,
          bulan: updatedData.bulan,
          tanggal: updatedData.tanggal,
          studentId: updatedData.studentId,
          tanpaKeterangan: updatedData.tanpaKeterangan,
          tahun: tahun,
          kelas: kelas,
          rombel: rombel,
          semester: semester,
          _id: updatedData._id,
          parentId: parentId,
        };

        setCurrentData(dataItem);
        setUpdatedData(dataItem);
        setModalIsOpen(true);
      } else {
        toast.error(`Data for ${_id} not found.`);
      }
    } else {
      toast.error(`Data for ${_id} not found.`);
    }
  };

  const handleAddEkskul = () => {
    setUpdatedData((prevData) => ({
      ...prevData,
      ekskul: [...prevData.ekskul, { ekskulName: "", ekskulKet: "" }],
    }));
  };

  const handleRemoveEkskul = (index) => {
    setUpdatedData((prevData) => ({
      ...prevData,
      ekskul: prevData.ekskul.filter((_, ekskulIndex) => ekskulIndex !== index),
    }));
  };

  const handleDelete = async (parentId) => {
    try {
      const token = localStorage.getItem("token");
      const url = `${process.env.REACT_APP_API_URL}api/dataRapor/delete/${parentId}`;

      await axios.delete(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setRaporData((prevData) =>
        prevData.filter((data) => data._id !== parentId)
      );
      toast.success("Data deleted successfully");
    } catch (error) {
      if (error.response) {
        toast.error(`Error: ${error.response.data.message}`);
      } else if (error.request) {
        toast.error("No response received from the server.");
      } else {
        toast.error(`Error setting up the request: ${error.message}`);
      }
    }
  };

  return (
    <div className="p-4 ">
      <h1 className="text-2xl font-bold mb-4">Rapor User List</h1>
      <ToastContainer />
      <div className="mb-4 grid sm:flex">
        {/* Input untuk filter */}
        <label htmlFor="tahun" className="mr-2">
          Tahun:
        </label>
        <input
          type="text"
          id="tahun"
          value={tahunFilter}
          onChange={(e) => setTahunFilter(e.target.value)}
          className="border border-gray-300 px-2 py-1 rounded mr-2"
        />
        <label htmlFor="semester" className="mr-2">
          Semester:
        </label>
        <select
          id="semester"
          value={semesterFilter}
          onChange={(e) => setSemesterFilter(e.target.value)}
          className="border border-gray-300 px-2 py-1 rounded mr-2"
        >
          <option value="">All</option>
          <option value="1">1</option>
          <option value="2">2</option>
        </select>
        <label htmlFor="kelas" className="mr-2">
          Kelas:
        </label>
        <select
          id="kelas"
          value={kelasFilter}
          onChange={(e) => setKelasFilter(e.target.value)}
          className="border border-gray-300 px-2 py-1 rounded mr-2"
        >
          <option value="">All</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
        </select>
        <label htmlFor="rombel" className="mr-2">
          Rombel:
        </label>
        <select
          id="rombel"
          value={rombelFilter}
          onChange={(e) => setRombelFilter(e.target.value)}
          className="border border-gray-300 px-2 py-1 rounded"
        >
          <option value="">All</option>
          <option value="A">A</option>
          <option value="B">B</option>
        </select>
      </div>
      <div className="my-5">
        <Link to={"/rapord-deskripsi-add"}>
          <button className="bg-blue-400 p-2 rounded-lg text-white font-bold hover:bg-blue-600">
            Tambah deskirpsi rapor
          </button>
        </Link>
      </div>
      {/* Tambahkan kondisi untuk menampilkan tabel */}
      {allFiltersFilled && (
        <div className="overflow-x-auto">
          {loading ? (
            <p>Loading...</p>
          ) : raporData.length === 0 ? (
            <p>No data available</p>
          ) : (
            <div>
              <table className="table-auto w-full border-collapse border border-gray-300">
                <thead>
                  <tr className="bg-gray-200">
                    <th className="border border-gray-300 px-4 py-2">Nama</th>
                    <th className="border border-gray-300 px-4 py-2">
                      Bulan penerimaan rapor
                    </th>
                    <th className="border border-gray-300 px-4 py-2">
                      Tanggal penerimaan rapor
                    </th>
                    <th className="border border-gray-300 px-4 py-2">Sakit</th>
                    <th className="border border-gray-300 px-4 py-2">Izin</th>
                    <th className="border border-gray-300 px-4 py-2">
                      Tanpa Keterangan
                    </th>
                    <th className="border border-gray-300 px-4 py-2">Ekskul</th>
                    <th className="border border-gray-300 px-4 py-2">
                      Ekskul Ket
                    </th>
                    <th className="border border-gray-300 px-4 py-2">
                      Catatan Walikelas
                    </th>
                    <th className="border border-gray-300 px-4 py-2">
                      Actions
                    </th>
                    <th className="border border-gray-300 px-4 py-2">View</th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {raporData.map((user) =>
                    user.dataKeterangan.map((item) => (
                      <tr key={item._id} className="border-b border-gray-300">
                        <td className="px-6 py-4 whitespace-nowrap">
                          {item.name}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {item.bulan}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {item.tanggal}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {item.sakit}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {item.izin}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {item.tanpaKeterangan}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <ul className="list-disc list-inside">
                            {item.ekskul.map((ekskulItem, index) => (
                              <li key={index}>{ekskulItem.ekskulName}</li>
                            ))}
                          </ul>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <ul className="list-disc list-inside">
                            {item.ekskul.map((ekskulItem, index) => (
                              <li key={index}>{ekskulItem.ekskulKet}</li>
                            ))}
                          </ul>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {item.CatatanaWakel}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <button
                            className="px-4 py-2 bg-blue-500 text-white rounded"
                            onClick={() => handleOpenModalByName(item._id)}
                          >
                            Edit
                          </button>
                        </td>
                        <td>
                          <Link
                            to={`/view-rapor/user_id/${user._id}/${item._id}/${item.studentId}/${user.tahun}/${user.semester}/${user.kelas}/${user.rombel}/${user.createdBy}`}
                          >
                            <div className="flex flex-row p-2">
                              <button className="bg-blue-100 text-md px-2 rounded-xl">
                                {" "}
                                View Rapor{" "}
                              </button>
                            </div>
                          </Link>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
              {raporData.map((user) => (
                <div key={user._id}>
                  <button
                    className="px-4 py-2 bg-red-500 text-white rounded ml-2"
                    onClick={() => handleDelete(user._id)}
                  >
                    Delete
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>
      )}

      {modalIsOpen && currentData && (
        <Modal isOpen={modalIsOpen} onRequestClose={closeModal}>
          <div className="bg-white p-3 md:p-6 rounded-lg shadow-lg w-full max-w-4xl mx-auto mt-5 text-sm md:text-normal">
            <h2 className="text-2xl font-bold mb-6 text-center">Update Data</h2>
            <form>
              <div className="flex flex-col sm:grid sm:grid-cols-2 gap-6">
                {/* Nama */}
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Nama:
                  </label>
                  <input
                    type="text"
                    name="name"
                    value={updatedData.name}
                    onChange={handleChange}
                    className="w-full border border-gray-300 px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>

                {/* Bulan */}
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Bulan:
                  </label>
                  <select
                    name="bulan"
                    value={updatedData.bulan}
                    onChange={handleChange}
                    className="w-full border border-gray-300 px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  >
                    <option value="">Pilih Bulan</option>
                    <option value="Januari">Januari</option>
                    <option value="Februari">Februari</option>
                    <option value="Maret">Maret</option>
                    <option value="April">April</option>
                    <option value="Mei">Mei</option>
                    <option value="Juni">Juni</option>
                    <option value="Juli">Juli</option>
                    <option value="Agustus">Agustus</option>
                    <option value="September">September</option>
                    <option value="Oktober">Oktober</option>
                    <option value="November">November</option>
                    <option value="Desember">Desember</option>
                  </select>
                </div>

                {/* Tanggal */}
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Tanggal:
                  </label>
                  <input
                    type="number"
                    name="tanggal"
                    value={updatedData.tanggal}
                    onChange={handleChange}
                    className="w-full border border-gray-300 px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>

                {/* Sakit */}
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Sakit:
                  </label>
                  <input
                    type="number"
                    name="sakit"
                    value={updatedData.sakit}
                    min={0}
                    onChange={handleChange}
                    className="w-full border border-gray-300 px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>

                {/* Izin */}
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Izin:
                  </label>
                  <input
                    type="number"
                    name="izin"
                    value={updatedData.izin}
                    min={0}
                    onChange={handleChange}
                    className="w-full border border-gray-300 px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>

                {/* Tanpa Keterangan */}
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Tanpa Keterangan:
                  </label>
                  <input
                    type="number"
                    name="tanpaKeterangan"
                    value={updatedData.tanpaKeterangan}
                    min={0}
                    onChange={handleChange}
                    className="w-full border border-gray-300 px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>

                {/* Ekskul */}
                {updatedData.ekskul &&
                  updatedData.ekskul.map((ekskul, ekskulIndex) => (
                    <div key={ekskulIndex} className="col-span-2 space-y-2">
                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-2">
                          Nama Ekskul:
                        </label>
                        <input
                          type="text"
                          name={`ekskulName-${ekskulIndex}`}
                          value={ekskul.ekskulName}
                          onChange={handleChange}
                          className="w-full border border-gray-300 px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-2">
                          Deskripsi Ekskul:
                        </label>
                        <input
                          type="text"
                          name={`ekskulKet-${ekskulIndex}`}
                          value={ekskul.ekskulKet}
                          onChange={handleChange}
                          className="w-full border border-gray-300 px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                      </div>
                      <button
                        type="button"
                        onClick={() => handleRemoveEkskul(ekskulIndex)}
                        className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-md"
                      >
                        Remove
                      </button>
                    </div>
                  ))}

                {/* Add Ekskul Button */}
                <div className="col-span-2">
                  <button
                    type="button"
                    onClick={handleAddEkskul}
                    className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-6 rounded-md"
                  >
                    Add Ekskul
                  </button>
                </div>

                {/* Catatan Walikelas */}
                <div className="col-span-2 mb-4">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Catatan Walikelas:
                  </label>
                  <textarea
                    rows="4"
                    name="CatatanaWakel"
                    value={updatedData.CatatanaWakel}
                    onChange={handleChange}
                    className="w-full border border-gray-300 px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>
              </div>

              {/* Tombol Simpan dan Batal */}
              <div className="mt-6 gap-2 flex flex-col sm:flex-row sm:justify-between sm:space-x-4">
                <button
                  type="button"
                  onClick={handleUpdate}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-6 rounded-md"
                >
                  Save
                </button>
                <button
                  type="button"
                  onClick={closeModal}
                  className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-6 rounded-md"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </Modal>
      )}
      <div className="my-10 flex justify-center">
        <Link to={"/guru"}>
          <Back />
        </Link>
      </div>
    </div>
  );
};

export default RaporUserList;
