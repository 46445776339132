import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Back from "../../components/button/back";

const EditAbsen = () => {
  const { id } = useParams();
  const navigaet = useNavigate();
  const [absensiList, setAbsensiList] = useState([]);
  const [filteredAbsensi, setFilteredAbsensi] = useState([]);
  const [error, setError] = useState("");

  // Filter states
  const [filterTanggal, setFilterTanggal] = useState("");
  const [filterKelas, setFilterKelas] = useState("");
  const [filterRombel, setFilterRombel] = useState("");
  const [tahun, setTahun] = useState("");
  const [semester, setSemester] = useState("");

  useEffect(() => {
    const fetchAllAbsensi = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) throw new Error("Unauthorized: No token found");

        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}api/absensi/getall`,
          { headers: { Authorization: `Bearer ${token}` } }
        );

        setAbsensiList(response.data);
      } catch (err) {
        setError(err.response?.data?.msg || "Failed to fetch absensi data");
      }
    };

    fetchAllAbsensi();
  }, []);

  const handleFilter = () => {
    let filtered = absensiList;

    if (filterTanggal) {
      filtered = filtered.filter(
        (absensi) =>
          new Date(absensi.tanggal).toLocaleDateString("en-CA") ===
          filterTanggal
      );
    }
    if (filterKelas) {
      filtered = filtered.filter((absensi) => absensi.kelas === filterKelas);
    }
    if (filterRombel) {
      filtered = filtered.filter((absensi) => absensi.rombel === filterRombel);
    }

    const siswaData = filtered.flatMap((absensi) =>
      (absensi.siswa || []).map((siswa) => ({
        ...siswa,
        absensiId: absensi._id,
        kelas: absensi.kelas,
        rombel: absensi.rombel,
        tanggal: absensi.tanggal,
        semester: absensi.semester,
        tahun: absensi.tahun,
      }))
    );

    setFilteredAbsensi(siswaData);
    if (filtered.length > 0) {
      setTahun(filtered[0].tahun);
      setSemester(filtered[0].semester);
    } else {
      setTahun("");
      setSemester("");
    }
  };

  const handleChange = (absensiId, siswaId, field, value) => {
    setFilteredAbsensi((prev) =>
      prev.map((siswa) =>
        siswa.absensiId === absensiId && siswa._id === siswaId
          ? { ...siswa, [field]: value }
          : siswa
      )
    );
  };

  const handleSave = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) throw new Error("Unauthorized: No token found");

      const groupedByAbsensi = filteredAbsensi.reduce((acc, siswa) => {
        const { absensiId, ...siswaData } = siswa;
        acc[absensiId] = acc[absensiId] || [];
        acc[absensiId].push(siswaData);
        return acc;
      }, {});

      for (const absensiId in groupedByAbsensi) {
        await axios.put(
          `${process.env.REACT_APP_API_URL}api/absensi/${absensiId}`,
          {
            siswa: groupedByAbsensi[absensiId].map((siswa) => ({
              ...siswa,
              sakit: Number(siswa.sakit) || 0,
              izin: Number(siswa.izin) || 0,
              tanpa_keterangan: Number(siswa.tanpa_keterangan) || 0,
            })),
            semester,
            tahun,
          },
          { headers: { Authorization: `Bearer ${token}` } }
        );
      }

      alert("Data successfully saved!");
    } catch (err) {
      setError(err.response?.data?.msg || "Failed to update absensi data");
    }
  };

  const handleDelete = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) throw new Error("Unauthorized: No token found");

      const absensiIds = [
        ...new Set(filteredAbsensi.map((siswa) => siswa.absensiId)),
      ];

      for (const absensiId of absensiIds) {
        await axios.delete(
          `${process.env.REACT_APP_API_URL}api/absensi/${absensiId}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
      }

      alert("Selected records successfully deleted!");
      setFilteredAbsensi([]);
      handleFilter(); // Refresh filtered data
    } catch (err) {
      setError(err.response?.data?.msg || "Failed to delete absensi data");
    }
  };

  return (
    <div className="h-full">
      <div className=" flex justify-center my-5">
        <h1 className="font-bold">Edit Absensi</h1>
      </div>

      {/* Filter Inputs */}
      <div className="flex flex-col md:flex-row items-center gap-4 md:gap-6 p-4 bg-gray-50 rounded-lg shadow-md">
        <div className="flex flex-col w-full md:w-auto">
          <label
            htmlFor="tanggal"
            className="text-sm font-medium text-gray-700"
          >
            Tanggal:
          </label>
          <input
            type="date"
            id="tanggal"
            value={filterTanggal}
            onChange={(e) => setFilterTanggal(e.target.value)}
            className="mt-1 block w-full md:w-48 border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
          />
        </div>
        <div className="flex flex-col w-full md:w-auto">
          <label htmlFor="kelas" className="text-sm font-medium text-gray-700">
            Kelas:
          </label>
          <select
            id="kelas"
            value={filterKelas}
            onChange={(e) => setFilterKelas(e.target.value)}
            className="mt-1 block w-full md:w-48 border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
          >
            <option value="">Pilih Kelas</option>
            {[1, 2, 3, 4, 5, 6].map((kelas) => (
              <option key={kelas} value={kelas}>
                {kelas}
              </option>
            ))}
          </select>
        </div>
        <div className="flex flex-col w-full md:w-auto">
          <label htmlFor="rombel" className="text-sm font-medium text-gray-700">
            Rombel:
          </label>
          <select
            id="rombel"
            value={filterRombel}
            onChange={(e) => setFilterRombel(e.target.value)}
            className="mt-1 block w-full md:w-48 border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
          >
            <option value="">Pilih Rombel</option>
            {["A", "B", "C"].map((rombel) => (
              <option key={rombel} value={rombel}>
                {rombel}
              </option>
            ))}
          </select>
        </div>
        <button
          onClick={handleFilter}
          className="mt-4 md:mt-0 px-6 py-2 text-sm font-medium text-white bg-blue-600 rounded-lg shadow hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          Filter
        </button>
      </div>

      {/* Tahun dan Semester */}
      {filteredAbsensi.length > 0 && (
        <div className="p-4 bg-gray-50 rounded-lg shadow-md space-y-4 md:space-y-0 md:space-x-6 md:flex md:items-center">
          <div className="flex flex-col w-full md:w-auto">
            <label
              htmlFor="tahun"
              className="text-sm font-medium text-gray-700"
            >
              Tahun:
            </label>
            <input
              type="text"
              id="tahun"
              value={tahun}
              onChange={(e) => setTahun(e.target.value)}
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
              placeholder="Masukkan Tahun"
            />
          </div>
          <div className="flex flex-col w-full md:w-auto">
            <label
              htmlFor="semester"
              className="text-sm font-medium text-gray-700"
            >
              Semester:
            </label>
            <select
              id="semester"
              value={semester}
              onChange={(e) => setSemester(e.target.value)}
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
            >
              <option value="">Pilih Semester</option>
              <option value="1">1</option>
              <option value="2">2</option>
            </select>
          </div>
        </div>
      )}

      {/* Data Table */}
      {filteredAbsensi.length > 0 && (
        <div className="overflow-x-auto max-w-full h-1/3 md:h-1/2">
          <table className="min-w-full table-auto border-collapse border border-gray-300">
            <thead className="bg-gray-100">
              <tr>
                <th className="px-4 py-2 border-b text-left text-sm font-medium text-gray-700">
                  No
                </th>
                <th className="px-4 py-2 border-b text-left text-sm font-medium text-gray-700">
                  Nama Siswa
                </th>
                <th className="px-4 py-2 border-b text-left text-sm font-medium text-gray-700">
                  Sakit
                </th>
                <th className="px-4 py-2 border-b text-left text-sm font-medium text-gray-700">
                  Izin
                </th>
                <th className="px-4 py-2 border-b text-left text-sm font-medium text-gray-700">
                  Tanpa Keterangan
                </th>
              </tr>
            </thead>
          </table>
          <div className="overflow-y-auto max-h-full">
            <table className="min-w-full table-auto border-collapse border border-gray-300">
              <tbody>
                {filteredAbsensi.map((siswa, index) => (
                  <tr key={index} className="hover:bg-gray-50">
                    <td className="px-4 py-2 border-b text-sm text-gray-800">
                      {index + 1}
                    </td>
                    <td className="px-4 py-2 border-b text-sm text-gray-800">
                      {siswa.namaSiswa}
                    </td>
                    <td className="px-4 py-2 border-b text-sm text-gray-800">
                      <input
                        type="number"
                        value={siswa.sakit}
                        onChange={(e) =>
                          handleChange(
                            siswa.absensiId,
                            siswa._id,
                            "sakit",
                            e.target.value
                          )
                        }
                        className="w-full px-2 py-1 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      />
                    </td>
                    <td className="px-4 py-2 border-b text-sm text-gray-800">
                      <input
                        type="number"
                        value={siswa.izin}
                        onChange={(e) =>
                          handleChange(
                            siswa.absensiId,
                            siswa._id,
                            "izin",
                            e.target.value
                          )
                        }
                        className="w-full px-2 py-1 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      />
                    </td>
                    <td className="px-4 py-2 border-b text-sm text-gray-800">
                      <input
                        type="number"
                        value={siswa.tanpa_keterangan}
                        onChange={(e) =>
                          handleChange(
                            siswa.absensiId,
                            siswa._id,
                            "tanpa_keterangan",
                            e.target.value
                          )
                        }
                        className="w-full px-2 py-1 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {/* Actions */}
      {filteredAbsensi.length > 0 && (
        <div className="space-y-4 sm:space-y-0 sm:flex sm:space-x-4 sm:items-center py-10 flex justify-center">
          <div className="flex justify-center sm:justify-start">
            <button
              onClick={handleSave}
              className="px-4 py-2 bg-blue-500 text-white rounded-lg shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
            >
              Save All Changes
            </button>
          </div>
          <div className="flex justify-center sm:justify-start">
            <button
              onClick={handleDelete}
              className="px-4 py-2 bg-red-500 text-white rounded-lg shadow-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
            >
              Delete Filtered Records
            </button>
          </div>
        </div>
      )}
      <div
        className="flex justify-center pb-5"
        onClick={() => navigaet(`/absensi/${id}`)}
      >
        <Back />
      </div>

      {/* Error Message */}
      {error && <div style={{ color: "red" }}>{error}</div>}
    </div>
  );
};

export default EditAbsen;
