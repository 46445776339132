import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const AddQuestionForm = () => {
  const [formData, setFormData] = useState({
    kelas: "",
    rombel: "",
    semester: "",
    mapel: "",
    jenis: "",
    kode: 1,
    questions: [
      {
        text: "",
        gambar: "",
        type: "essay",
        keyJawabanBenar: 1,
        optionCorrectAnswer: [],
        bobot: 1,
        correctAnswer: "",
        options: [],
      },
    ],
    status: false,
  });
  const navigate = useNavigate();
  const handleChange = (e, index, optionIndex) => {
    const { name, value } = e.target;

    // Update formData fields outside of questions
    if (["kelas", "rombel", "mapel", "jenis", "semester"].includes(name)) {
      setFormData({ ...formData, [name]: value });
      return;
    }

    // Clone questions array for safe updates
    const updatedQuestions = [...formData.questions];

    // Handle question-specific changes
    switch (name) {
      case "type":
        updatedQuestions[index].type = value;
        // Reset related fields based on question type
        if (value === "essay") {
          updatedQuestions[index].options = [];
          updatedQuestions[index].correctAnswer = "";
          updatedQuestions[index].optionCorrectAnswer = undefined;
        } else if (value === "isian") {
          updatedQuestions[index].optionCorrectAnswer = [];
          updatedQuestions[index].correctAnswer = undefined;
          updatedQuestions[index].options = undefined;
        } else if (value === "multipleChoice") {
          updatedQuestions[index].options = [
            { text: "", gambar: "", isCorrect: false },
          ];
          updatedQuestions[index].correctAnswer = "";
          updatedQuestions[index].optionCorrectAnswer = undefined;
        }
        break;

      case "optionText":
        if (optionIndex !== undefined) {
          updatedQuestions[index].options[optionIndex].text = value;
        }
        break;

      case "correctAnswer":
        updatedQuestions[index].correctAnswer = value;
        break;

      case "bobot":
        updatedQuestions[index].bobot = Number(value);
        break;

      case "keyJawabanBenar":
        updatedQuestions[index].keyJawabanBenar = Number(value);
        break;

      default:
        if (name.startsWith("optionCorrectAnswer")) {
          const keyIndex = parseInt(
            name.replace("optionCorrectAnswer", ""),
            10
          );
          if (keyIndex >= 0) {
            const updatedAnswers = [
              ...updatedQuestions[index].optionCorrectAnswer,
            ];
            updatedAnswers[keyIndex] = value;
            updatedQuestions[index].optionCorrectAnswer = updatedAnswers;
          }
        } else {
          updatedQuestions[index][name] = value;
        }
        break;
    }

    // Update the state with modified questions
    setFormData({ ...formData, questions: updatedQuestions });
  };

  const addQuestion = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      kode: prevFormData.kode + 1,
      questions: [
        ...prevFormData.questions,
        { text: "", type: "essay", bobot: 1, correctAnswer: "", options: [] },
      ],
    }));
  };

  const removeQuestion = (index) => {
    const updatedQuestions = [...formData.questions];
    updatedQuestions.splice(index, 1);
    setFormData((prevFormData) => ({
      ...prevFormData,
      kode: prevFormData.kode - 1,
      questions: updatedQuestions,
    }));
  };

  const addOption = (index) => {
    const updatedQuestions = [...formData.questions];
    updatedQuestions[index].options.push({
      text: "",
      gambar: "",
      isCorrect: false,
    });
    setFormData({ ...formData, questions: updatedQuestions });
  };

  const removeOption = (questionIndex, optionIndex) => {
    const updatedQuestions = [...formData.questions];
    updatedQuestions[questionIndex].options.splice(optionIndex, 1);
    setFormData({ ...formData, questions: updatedQuestions });
  };

  const handleImageUpload = async (e, index) => {
    try {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append("image", file); // Mengirim file ke backend

      const token = localStorage.getItem("token");

      const response = await axios.post(
          `${process.env.REACT_APP_API_URL}api/questions/upload`,
          formData,
          {
              headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: `Bearer ${token}`,
              },
          }
      );
      const downloadURL = response.data.url;
      const updatedQuestions = [...formData.questions];
      updatedQuestions[index].gambar = downloadURL;

      setFormData({ ...formData, questions: updatedQuestions });
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const handleOptionImageUpload = async (e, questionIndex, optionIndex) => {
    try {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append("image", file); // Mengirim file ke backend

      const token = localStorage.getItem("token");

      const response = await axios.post(
          `${process.env.REACT_APP_API_URL}api/questions/upload`,
          formData,
          {
              headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: `Bearer ${token}`,
              },
          }
      );
      const downloadURL = response.data.url;
      const updatedQuestions = [...formData.questions];
      updatedQuestions[questionIndex].options[optionIndex].gambar = downloadURL;

      setFormData({ ...formData, questions: updatedQuestions });
    } catch (error) {
      console.error("Error uploading option image:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Validasi token
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("Authentication token not found. Please log in again.");
        return;
      }

      // Validasi formData
      if (
        !formData.kelas ||
        !formData.rombel ||
        !formData.semester ||
        !formData.mapel ||
        !formData.jenis
      ) {
        console.error("Please complete all required fields.");
        return;
      }

      if (
        formData.questions.some(
          (q) =>
            !q.text ||
            q.bobot <= 0 ||
            (q.type === "multipleChoice" && q.options.length === 0)
        )
      ) {
        console.error("Some questions are incomplete or invalid.");
        return;
      }

      // Kirim request ke API
      await axios.post(
        `${process.env.REACT_APP_API_URL}api/questions`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Reset form
      setFormData({
        kelas: "",
        rombel: "",
        semester: "",
        mapel: "",
        jenis: "",
        kode: 1,
        questions: [
          {
            text: "",
            gambar: "",
            type: "essay",
            keyJawabanBenar: 0,
            bobot: 1,
            correctAnswer: "",
            options: [],
            optionCorrectAnswer: [],
          },
        ],
      });

      // Redirect setelah sukses
      navigate("/guru");
    } catch (error) {
      // Penanganan error
      if (error.response) {
        console.error("Error response from server:", error.response.data);
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Error occurred:", error.message);
      }
    }
  };

  return (
    <div>
      <div className="flex justify-center items-center py-10">
        <h2 className="font-bold text-2xl">Add Questions</h2>
      </div>

      <form
        onSubmit={handleSubmit}
        className="grid  bg-blue-200/40 mx-5 px-3 py-5 mb-10 rounded-lg "
      >
        <div className="grid  sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 gap-y-3 rounded-lg border border-slate-500/30 p-2 mb-5">
          <div className="flex items-center gap-1">
            <label className="font-bold">Kelas : </label>
            <select
              name="kelas"
              value={formData.kelas}
              onChange={handleChange}
              required
              className="rounded-lg py-2 px-[110px] "
            >
              <option value="">Kelas</option>
              {["1", "2", "3", "4", "5", "6"].map((kelas) => (
                <option key={kelas} value={kelas}>
                  {kelas}
                </option>
              ))}
            </select>
          </div>
          <div className="flex items-center gap-1">
            <label className="font-bold">Rombel : </label>
            <select
              name="rombel"
              value={formData.rombel}
              onChange={handleChange}
              required
              className="rounded-lg px-[92px] py-2"
            >
              <option value="">Rombel</option>
              {["A", "B"].map((rombel) => (
                <option key={rombel} value={rombel}>
                  {rombel}
                </option>
              ))}
            </select>
          </div>
          <div className="flex items-center gap-1">
            <label className="font-bold">Semester : </label>
            <select
              name="semester"
              value={formData.semester}
              onChange={handleChange}
              required
              className="rounded-lg px-[92px] py-2"
            >
              <option value="">Semester</option>
              {["1", "2"].map((semester) => (
                <option key={semester} value={semester}>
                  {semester}
                </option>
              ))}
            </select>
          </div>
          <div className="flex items-center gap-1">
            <label className="font-bold">Mapel : </label>
            <select
              name="mapel"
              value={formData.mapel}
              onChange={handleChange}
              required
              className="rounded-lg px-[102px] py-2"
            >
              <option value="">Mapel</option>
              {[
                "IPA",
                "IPS",
                "MTK",
                "BIndo",
                "BIng",
                "IPAS",
                "PAI",
                "PKN",
                "PJOK",
                "SENIBUDAYA",
                "MULOK",
              ].map((mapel) => (
                <option key={mapel} value={mapel}>
                  {mapel}
                </option>
              ))}
            </select>
          </div>
          <div className="flex           items-center gap-1">
            <label className="font-bold">Jenis : </label>
            <select
              name="jenis"
              value={formData.jenis}
              onChange={handleChange}
              required
              className="rounded-lg px-[103px] py-2"
            >
              <option value="">Jenis</option>
              {["Latihan", "Tugas", "PR", "UH", "MID", "US", "Praktikum"].map(
                (jenis) => (
                  <option key={jenis} value={jenis}>
                    {jenis}
                  </option>
                )
              )}
            </select>
          </div>
          <div className="flex  items-center gap-1">
            <div>
              <h1 className="font-bold">Jumlah Soal : </h1>
            </div>

            <input
              className="bg-transparent "
              type="number"
              name="kode"
              value={formData.kode}
              readOnly
            />
          </div>
        </div>
        <h1 className="font-bold text-lg">Soal</h1>
        {formData.questions.map((question, index) => (
          <div
            key={index}
            className=" grid border border-slate-400/60 rounded-lg p-2 gap-y-3"
          >
            <div className="grid">
              <label>Soal nomor {index + 1}:</label>
              <textarea
                name="text"
                value={question.text}
                onChange={(e) => handleChange(e, index)}
                className="rounded-lg px-2 w-full h-32"
              />
            </div>
            <div className="flex items-center gap-1">
              <label htmlFor={`image${index}`} className="font-bold">
                Upload Image:
              </label>
              <input
                type="file"
                id={`image${index}`}
                name="image"
                accept="image/*"
                onChange={(e) => handleImageUpload(e, index)}
              />
              {question.gambar && (
                <img
                  src={question.gambar}
                  alt={`Preview Soal ${index + 1}`}
                  className="w-32 h-32 object-cover mt-2"
                />
              )}
            </div>
            <div>
              <label>Question Type : </label>
              <select
                name="type"
                value={question.type}
                onChange={(e) => handleChange(e, index)}
                className="rounded-lg px-4 py-2"
              >
                <option value="multipleChoice">Pilihan Ganda</option>
                <option value="isian">Isian Singkat</option>
                <option value="essay">Essay</option>
              </select>
            </div>

            <div>
              <label>Bobot : </label>
              <input
                type="number"
                name="bobot"
                value={question.bobot}
                onChange={(e) => handleChange(e, index)}
                className="rounded-lg px-4 py-2"
                min="1"
              />
            </div>

            {question.type === "essay" && (
              <div className="grid">
                <label>Correct Answer:</label>
                <textarea
                  type="text"
                  name={"correctAnswer"}
                  value={question.correctAnswer}
                  onChange={(e) => handleChange(e, index)}
                  className="w-full h-20"
                />
              </div>
            )}
            {question.type === "multipleChoice" && (
              <div>
                <label>Options :</label>
                {question.options.map((option, optionIndex) => (
                  <div key={optionIndex} className="flex gap-3 my-3">
                    <input
                      type="text"
                      name="optionText"
                      value={option.text}
                      onChange={(e) => handleChange(e, index, optionIndex)}
                      className="rounded-lg py-2 ph"
                    />
                    <div className="flex items-center gap-1">
                      <label
                        htmlFor={`imageOption${index}_${optionIndex}`}
                        className="font-bold"
                      >
                        Upload Image:
                      </label>
                      <input
                        type="file"
                        id={`imageOption${index}_${optionIndex}`}
                        name={`optionImage`}
                        accept="image/*"
                        onChange={(e) =>
                          handleOptionImageUpload(e, index, optionIndex)
                        }
                      />
                      {option.gambar && (
                        <img
                          src={option.gambar}
                          alt={`Preview Soal ${index + 1}`}
                          className="w-32 h-32 object-cover mt-2"
                        />
                      )}
                    </div>
                    <input
                      type="radio"
                      name={`correctAnswer${index}`}
                      value={optionIndex}
                      checked={option.isCorrect}
                      onChange={(e) => {
                        const updatedQuestions = [...formData.questions];
                        updatedQuestions[index].options = updatedQuestions[
                          index
                        ].options.map((opt, idx) => ({
                          ...opt,
                          isCorrect: idx === optionIndex,
                        }));
                        setFormData({
                          ...formData,
                          questions: updatedQuestions,
                        });
                      }}
                    />
                    <button
                      type="button"
                      onClick={() => removeOption(index, optionIndex)}
                      className="px-3 rounded-lg bg-red-500/50 py-1"
                    >
                      Hapus
                    </button>
                  </div>
                ))}
                <button
                  className="bg-blue-500/25 p-2 rounded-lg font-semibold "
                  type="button"
                  onClick={() => addOption(index)}
                >
                  Add Option
                </button>
              </div>
            )}
            {question.type === "isian" && (
              <div>
                <label>Jumlah jawaban benar: </label>
                <input
                  type="number"
                  min="1"
                  value={question.keyJawabanBenar}
                  onChange={(e) => {
                    const keyJawabanBenar = parseInt(e.target.value, 10) || 0;
                    setFormData((prev) => {
                      const updatedQuestions = [...prev.questions];
                      updatedQuestions[index].keyJawabanBenar = keyJawabanBenar;
                      return { ...prev, questions: updatedQuestions };
                    });
                  }}
                  className="rounded-lg px-4 py-2"
                />
                <div className="mt-5">
                  <p>Kunci Jawaban</p>
                  {question.optionCorrectAnswer.map((answer, i) => (
                    <div key={i} className="flex items-center my-2">
                      <input
                        type="text"
                        value={answer}
                        onChange={(e) => {
                          const updatedAnswers = [
                            ...question.optionCorrectAnswer,
                          ]; // Salin array jawaban
                          updatedAnswers[i] = e.target.value; // Perbarui jawaban pada indeks tertentu

                          // Perbarui state formData
                          setFormData((prev) => {
                            const updatedQuestions = [...prev.questions];
                            updatedQuestions[index].optionCorrectAnswer =
                              updatedAnswers;
                            return { ...prev, questions: updatedQuestions };
                          });
                        }}
                        className="rounded-lg px-4 py-2 border border-slate-300 flex-grow"
                        placeholder={`Jawaban ${i + 1}`}
                      />
                      <button
                        type="button"
                        onClick={() => {
                          setFormData((prev) => {
                            const updatedQuestions = [...prev.questions];
                            const updatedAnswers = [
                              ...updatedQuestions[index].optionCorrectAnswer,
                            ];
                            updatedAnswers.splice(i, 1); // Hapus jawaban berdasarkan indeks
                            updatedQuestions[index].optionCorrectAnswer =
                              updatedAnswers;
                            return { ...prev, questions: updatedQuestions };
                          });
                        }}
                        className="ml-2 text-red-500 hover:underline"
                      >
                        Hapus
                      </button>
                    </div>
                  ))}
                  <button
                    type="button"
                    onClick={() => {
                      setFormData((prev) => {
                        // Salin array jawaban dengan aman
                        const updatedQuestions = JSON.parse(
                          JSON.stringify(prev.questions)
                        );
                        updatedQuestions[index].optionCorrectAnswer.push(""); // Tambahkan jawaban kosong
                        return { ...prev, questions: updatedQuestions };
                      });
                    }}
                    className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                  >
                    Tambah Jawaban
                  </button>
                  <div className="mt-4"></div>
                </div>
              </div>
            )}

            <button
              className="bg-red-400 rounded-lg py-2 my-5"
              type="button"
              onClick={() => removeQuestion(index)}
            >
              Remove Question
            </button>
          </div>
        ))}

        <button
          className="bg-blue-500/25 my-5 py-3 rounded-lg"
          type="button"
          onClick={addQuestion}
        >
          Add Question
        </button>
        <button className="bg-blue-400 py-3  rounded-lg" type="submit">
          Submit
        </button>
      </form>
    </div>
  );
};

export default AddQuestionForm;
