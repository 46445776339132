import axios from "axios";
import React, { useEffect, useState } from "react";
import { FiEdit } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import { Link } from "react-router-dom";
import Back from "../components/button/back";

const AdminList = () => {
  const [admins, setAdmins] = useState([]);

  useEffect(() => {
    const fetchAdmins = async () => {
      try {
        const token = localStorage.getItem("token");

        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}api/auth/users`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setAdmins(response.data.filter((user) => user.role === "admin"));
      } catch (error) {
        console.error("Error fetching admins:", error);
      }
    };

    fetchAdmins();
  }, []);

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this user?"
    );
    if (!confirmDelete) return;

    try {
      const token = localStorage.getItem("token");
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}api/auth/users/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200 || response.status === 204) {
        setAdmins(admins.filter((user) => user.id !== id)); // Menggunakan properti 'id'
        alert("User deleted successfully");
        window.location.reload();
      } else {
        console.error("Error deleting user:", response.status);
      }
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  return (
    <div className="min-h-screen bg-blue-100">
      <div className="px-5 py-5">
        <div className="flex justify-center">
          <h1 className="text-3xl font-bold text-gray-800">Data Admin</h1>
        </div>

        <div className="my-10 flex justify-end">
          <Link
            to={"/admin/add-user"}
            className="bg-blue-800 text-white px-5 py-3 rounded-full hover:bg-blue-600 font-bold transition duration-300"
          >
            Tambah Pengguna
          </Link>
        </div>
        <div className="overflow-x-auto shadow-md sm:rounded-lg">
          <table className="min-w-full divide-y divide-gray-200 bg-white">
            <thead className="bg-gradient-to-tr from-cyan-600 to-cyan-400">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                  NO
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                  Foto
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                  Name
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                  Status
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                  NIP
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                  Kelamin
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                  Tempat Lahir
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                  Tanggal Lahir
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                  Edit
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                  Hapus
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {admins.map((user, index) => (
                <tr
                  key={user._id}
                  className="hover:bg-gray-100 transition duration-150"
                >
                  <td className="px-6 py-4 whitespace-nowrap">{index + 1}</td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <img
                      src={user.image}
                      alt={`foto ${user.name}`}
                      className="w-10 h-10 rounded-full object-cover"
                    />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">{user.name}</td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {user.statusPPH}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">{user.NIP}</td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {user.kelamin}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">{user.TL}</td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {new Date(user.TGL).toLocaleDateString()}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <Link
                      to={`/admin/edit-user/${user._id}`}
                      className="text-blue-500 hover:text-blue-700"
                    >
                      <FiEdit />
                    </Link>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <MdDelete
                      onClick={() => handleDelete(user._id)}
                      className="text-red-500 hover:text-red-700 cursor-pointer"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="mx-5">
        <Link to={"/admin"}>
          <Back />
        </Link>
      </div>
    </div>
  );
};

export default AdminList;
