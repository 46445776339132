import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const RaporMapel = ({ mapel }) => {
  const [nilaiFinal, setNilaiFinal] = useState([]);
  const [tp, setTP] = useState([]);
  const [tahun, setTahun] = useState("");
  const [semester, setSemester] = useState("");
  const [kelas, setKelas] = useState("");
  const [rombel, setRombel] = useState("");
  const [selectedTP, setSelectedTP] = useState({});

  useEffect(() => {
    const fetchNilaiFinalByMapel = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}api/nilaiFinal/mapel/${mapel}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              tahun,
              semester,
              kelas,
              rombel,
            },
          }
        );

        setNilaiFinal(response.data);
      } catch (error) {
        console.error("Error fetching NilaiFinal data:", error);
        setNilaiFinal([]); // Set to empty array on error
      }
    };

    const fetchTPByMapel = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}api/tp/mapel/${mapel}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              semester,
              kelas,
            },
          }
        );

        setTP(response.data);
      } catch (error) {
        console.error("Error fetching TP data:", error);
        setTP([]); // Set to empty array on error
      }
    };

    fetchNilaiFinalByMapel();
    fetchTPByMapel();
  }, [mapel, tahun, semester, kelas, rombel]);

  const handleTPChange = (studentId, type, checked, isiTP) => {
    setSelectedTP((prevSelectedTP) => {
      const updatedTP = { ...prevSelectedTP };
      if (!updatedTP[studentId]) {
        updatedTP[studentId] = { terpenuhi: [], peningkatan: [] };
      }
      if (checked) {
        if (!updatedTP[studentId][type].includes(isiTP)) {
          updatedTP[studentId][type].push(isiTP);
        }
      } else {
        updatedTP[studentId][type] = updatedTP[studentId][type].filter(
          (tp) => tp !== isiTP
        );
      }
      return updatedTP;
    });
  };

  const handleSave = async () => {
    const token = localStorage.getItem("token");

    // Filter data to be saved based on current table view
    const dataToSave = filteredNilaiFinal.flatMap((item) =>
      item.nilai.map((nilai) => ({
        name: nilai.name,
        finalScore: nilai.finalScore,
        studentId: nilai.studentId,
        CPtercapi: selectedTP[nilai._id]?.terpenuhi || [],
        CPditingkatkan: selectedTP[nilai._id]?.peningkatan || [],
      }))
    );

    const requestBody = {
      mapel,
      kelas,
      rombel,
      tahun,
      semester,
      nilai: dataToSave,
    };

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}api/nilaiRapor`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      toast.success("Data berhasil disimpan!");
    } catch (error) {
      console.error("Error saving data:", error);
      if (error.response && error.response.status === 400) {
        toast.error(
          "Data dengan tahun, semester, kelas, dan rombel ini sudah ada!"
        );
      } else {
        toast.error("Terjadi kesalahan saat menyimpan data!");
      }
    }
  };

  const truncateText = (text, maxWords) => {
    const words = text.split(" ");
    if (words.length > maxWords) {
      return words.slice(0, maxWords).join(" ") + " ...";
    }
    return text;
  };

  const filteredNilaiFinal = nilaiFinal.filter(
    (item) =>
      (!tahun || item.tahun === tahun) &&
      (!semester || item.semester === semester) &&
      (!kelas || item.kelas === kelas) &&
      (!rombel || item.rombel === rombel)
  );

  const filteredTP = tp.filter(
    (item) =>
      (!semester || item.semester === semester) &&
      (!kelas || item.kelas === kelas)
  );

  return (
    <div className="bg-white rounded-lg md:p-5 drop-shadow-lg overflow-x-auto">
      <ToastContainer />
      <div className="flex justify-center">
        <h2 className="font-bold text-xl md:text-normal">Daftar Nilai</h2>
      </div>

      <div>
        <div className="grid gap-4 border border-slate-500 p-2 rounded-lg bg-white shadow-md">
          {/* Tahun */}
          <div className="flex items-center gap-4">
            <label className="w-1/4 font-medium">Tahun:</label>
            <input
              type="text"
              value={tahun}
              onChange={(e) => setTahun(e.target.value)}
              className="flex-1 border border-slate-500 rounded-lg px-4 py-2"
            />
          </div>

          {/* Semester */}
          <div className="flex items-center gap-4">
            <label className="w-1/4 font-medium">Semester:</label>
            <select
              value={semester}
              onChange={(e) => setSemester(e.target.value)}
              className="flex-1 border border-slate-500 rounded-lg px-4 py-2"
            >
              <option value="">Pilih Semester</option>
              <option value="1">1</option>
              <option value="2">2</option>
            </select>
          </div>

          {/* Kelas */}
          <div className="flex items-center gap-4">
            <label className="w-1/4 font-medium">Kelas:</label>
            <select
              value={kelas}
              onChange={(e) => setKelas(e.target.value)}
              className="flex-1 border border-slate-500 rounded-lg px-4 py-2"
            >
              <option value="">Pilih Kelas</option>
              {[1, 2, 3, 4, 5, 6].map((kelas) => (
                <option key={kelas} value={kelas}>
                  {kelas}
                </option>
              ))}
            </select>
          </div>

          {/* Rombel */}
          <div className="flex items-center gap-4">
            <label className="w-1/4 font-medium">Rombel:</label>
            <select
              value={rombel}
              onChange={(e) => setRombel(e.target.value)}
              className="flex-1 border border-slate-500 rounded-lg px-4 py-2"
            >
              <option value="">Pilih Rombel</option>
              <option value="A">A</option>
              <option value="B">B</option>
            </select>
          </div>
        </div>
      </div>

      <div className="flex justify-center my-5 ">
        <h3 className="font-bold ">Nilai Mata Pelajaran</h3>
      </div>
      <div className="overflow-x-auto overflow-y-auto  h-[400px] md:h-auto w-auto sm:w-auto md:w-auto">
        {filteredNilaiFinal.length > 0 ? (
          <table className="min-w-full  overflow-x-scroll    ">
            <thead className="bg-gradient-to-tr from-cyan-600 to-cyan-400">
              <tr>
                <th className="p-2">Nama Siswa</th>
                <th className="p-2">Nilai Akhir</th>
                <th className="p-2">CP yang terpenuhi</th>
                <th className="p-2">CP Perlu peningkatan</th>
              </tr>
            </thead>
            <tbody className="border border-slate-500 ">
              {filteredNilaiFinal.map((item) =>
                item.nilai.map((nilai) => (
                  <tr key={nilai._id}>
                    <td className="p-2">{nilai.name}</td>
                    <td className="p-2">{nilai.finalScore}</td>
                    <td className="p-2">
                      {filteredTP.map((tpItem) =>
                        tpItem.TP.map((isi) => (
                          <div key={isi._id}>
                            <input
                              type="checkbox"
                              id={`${nilai._id}_${isi._id}_terpenuhi`}
                              name="isiTP_terpenuhi"
                              value={isi.isiTP}
                              onChange={(e) =>
                                handleTPChange(
                                  nilai._id,
                                  "terpenuhi",
                                  e.target.checked,
                                  isi.isiTP
                                )
                              }
                            />
                            <label
                              htmlFor={`${nilai._id}_${isi._id}_terpenuhi`}
                              className="pl-1"
                            >
                              {truncateText(isi.isiTP, 6)}
                            </label>
                          </div>
                        ))
                      )}
                    </td>
                    <td className="p-2">
                      {filteredTP.map((tpItem) =>
                        tpItem.TP.map((isi) => (
                          <div key={isi._id}>
                            <input
                              type="checkbox"
                              id={`${nilai._id}_${isi._id}_peningkatan`}
                              name="isiTP_peningkatan"
                              value={isi.isiTP}
                              onChange={(e) =>
                                handleTPChange(
                                  nilai._id,
                                  "peningkatan",
                                  e.target.checked,
                                  isi.isiTP
                                )
                              }
                            />
                            <label
                              htmlFor={`${nilai._id}_${isi._id}_peningkatan`}
                              className="pl-1"
                            >
                              {truncateText(isi.isiTP, 6)}
                            </label>
                          </div>
                        ))
                      )}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        ) : (
          <p className="text-center">
            Tidak ada data Nilai Final yang ditemukan untuk filter yang
            diterapkan.
          </p>
        )}
      </div>
      <div className="flex justify-center mt-4">
        <button
          onClick={handleSave}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Simpan Data
        </button>
      </div>
    </div>
  );
};

export default RaporMapel;
