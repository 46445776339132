import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Back from "../components/button/back";

const AnswerQuestion = () => {
  const { mapel, questionId } = useParams();
  const [answers, setAnswers] = useState([]);
  const [user, setUser] = useState({});
  const [OptionCorrectAnswer, setOptionCorrectAnswer] = useState({});
  const [Hasil, setHasil] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [hasToken, setHasToken] = useState(false);
  const [essayAnswers, setEssayAnswers] = useState({}); // Menampung jawaban essay per soal
  const [inputValues, setInputValues] = useState([""]);
  const [questionData, setQuestionData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 10000);
    const fetchQuestion = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}api/questions/${questionId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setQuestionData(response.data);

        const initialAnswers = response.data.question.map((q, questionIndex) => {
          // Check if the question is of type 'isian'
          if (q.type === "isian") {
            return {
              text: (inputValues[questionIndex] || []).join("|"), // Handle 'isian' type
              type: "isian",
              bobot: q.bobot || 1,
              gambar: "",
              keyJawabanBenar: q.keyJawabanBenar,
              optionCorrectAnswer: q.optionCorrectAnswer

            };
          }
          else if (q.type === "essay") {
            return {
              text: (essayAnswers[questionIndex] || []), // Handle 'isian' type
              type: "essay",
              bobot: q.bobot || 1,
              gambar: "",
              correctAnswer: q.correctAnswer
            };
          }
          else {
            // For other types (like 'multipleChoice', 'essay', etc.), don't change the structure
            return {
              text: "",
              type: q.type,
              bobot: q.bobot,
              isCorrect: q.isCorrect,
              gambar: "", // initialize gambar property for all answers

            };
          }
        });

        setAnswers(initialAnswers);

      } catch (error) {
        console.error("Error fetching question:", error);
      }
    };

    const fetchAnswerStatus = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}api/answers/status/${questionId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data.hasSubmitted) {
          setSubmitted(true);
        }
      } catch (error) {
        console.error("Error fetching answer status:", error);
      }
    };

    fetchQuestion();
    fetchAnswerStatus();
  }, [questionId, inputValues]); // Hanya mendengarkan perubahan pada `questionId`
  // Hanya mendengarkan perubahan pada `questionId`
  useEffect(() => { }, [submitted]);

  useEffect(() => {
    const fetchQuestionData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}api/questions/${questionId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setQuestionData(response.data);

        // Ambil `OptionCorrectAnswer` untuk setiap soal
        const arrayCorrectAnswers = {};
        response.data.question.forEach((q, index) => {
          arrayCorrectAnswers[index] = q.optionCorrectAnswer.map((answer) =>
            answer.toLowerCase()
          );
        });
        setOptionCorrectAnswer(arrayCorrectAnswers);
      } catch (error) {
        console.error("Error fetching question data:", error);
      }
    };

    fetchQuestionData();
  }, [questionId]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    setHasToken(!!token);

    if (token) {
      axios
        .get(`${process.env.REACT_APP_API_URL}api/auth/profile`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setUser(response.data._id);
        })
        .catch((error) => {
          console.error("Error fetching user profile:", error);
        });
    }
  }, []);

  useEffect(() => {
    const fetchHasil = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}api/answers/question/${questionId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const dataAnswr = response.data.filter((s) => s.studentId._id === user);
        setHasil(dataAnswr);
        const similarityPromises = dataAnswr.map(async (answer) => {
          const questionComparisons = await Promise.all(
            answer.question.map(async (q, index) => {
              try {
                if (q.type === "isian") {
                  const arrayCorrectAnswers = OptionCorrectAnswer[index] || [];
                  if (!arrayCorrectAnswers.length) {
                    return {
                      ...q,
                      similarity: 0,
                    };
                  }
                  const keyJawabanBenar = q.keyJawabanBenar || 1; // Default jika kosong
                  const userAnswerArray = Array.isArray(q.userAnswer)
                    ? q.userAnswer.map((ans) => ans.trim().toLowerCase())
                    : q.userAnswer
                      ?.split("|")
                      ?.map((ans) => ans.trim().toLowerCase()) || [];

                  const intersectionCount = userAnswerArray.filter((userAns) =>
                    arrayCorrectAnswers.includes(userAns)
                  ).length;
                  return {
                    ...q,
                    similarity: (intersectionCount / keyJawabanBenar) * q.bobot, // Persentase kesamaan
                  };
                }

                const correctAnswer = q.correctAnswer?.trim().toLowerCase();
                const userAnswer = q.userAnswer?.trim().toLowerCase();

                if (!correctAnswer || !userAnswer) {
                  return { ...q, similarity: 0 };
                }

                const compareResponse = await axios.post(
                  `${process.env.REACT_APP_API_URL}api/compare`,
                  {
                    text1: correctAnswer,
                    text2: userAnswer,
                    bobot: q.bobot,
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
                );
                const similarity = compareResponse?.data?.similarity || 0;
                return {
                  ...q,
                  similarity,
                };
              } catch (error) {
                console.error("Error during comparison:", error);
                return { ...q, similarity: 0, isCorrect: 0 };
              }
            })
          );
          return { ...answer, question: questionComparisons };
        });
        const answersWithSimilarity = await Promise.all(similarityPromises);
        setHasil(answersWithSimilarity);
      } catch (error) {
        console.error("Error fetching answers:", error);
      }
    };
    fetchHasil();
  }, [questionId, user, OptionCorrectAnswer]);

  const handleInputChangeIsian = (nomorSoal, index, value) => {
    // Buat salinan dari state inputValues dan answers
    const updatedInputValues = { ...inputValues };
    const updatedAnswers = [...answers];

    // Inisialisasi array jika belum ada untuk soal ini
    if (!updatedInputValues[nomorSoal]) {
      updatedInputValues[nomorSoal] = [];
    }

    // Update input value pada index yang sesuai
    updatedInputValues[nomorSoal][index] = value;

    // Update jawaban pada soal "isian" tanpa mereset `text`
    if (updatedAnswers[nomorSoal].type === 'isian') {
      updatedAnswers[nomorSoal] = {
        ...updatedAnswers[nomorSoal], // Menyebarkan properti lain
        text: updatedInputValues[nomorSoal].join("|"), // Gabungkan nilai jika ada beberapa jawaban
      };
    }

    // Update state dengan nilai baru
    setInputValues(updatedInputValues);
    setAnswers(updatedAnswers);
  };
  const handleEssayChange = (index, value) => {
    // Update state essayAnswers
    setEssayAnswers(prevState => ({
      ...prevState,
      [index]: value, // Menyimpan jawaban essay per soal berdasarkan index
    }));

    // Update state answers untuk soal essay
    const updatedAnswers = [...answers];
    updatedAnswers[index] = {
      ...updatedAnswers[index], // Menyebarkan properti lain
      text: value, // Menyimpan jawaban essay di answers
    };

    setAnswers(updatedAnswers); // Update answers state
  };

  const handleInputChange = (index, value, isCorrect = false) => {
    const newAnswers = [...answers];

    if (questionData.question[index].type === "multipleChoice") {
      newAnswers[index] = {
        text: value,
        gambar: answers[index].gambar,
        isCorrect,
        type: questionData.question[index].type,
        bobot: questionData.question[index].bobot,
      };
    } else if (questionData.question[index].type === "essay") {
      newAnswers[index] = {
        text: essayAnswers[index],
        type: questionData.question[index].type,
        bobot: questionData.question[index].bobot,
        gambar: answers[index].gambar,
      };
    }

    setAnswers(newAnswers);
  };

  const compareAnswers = async (correctAnswer, userAnswer, bobot, token) => {
    try {
      if (!correctAnswer || !userAnswer) {
        return 0; // Jika salah satu jawaban kosong, langsung return 0
      }

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}api/compare`,
        {
          text1: correctAnswer.trim().toLowerCase(),
          text2: userAnswer.trim().toLowerCase(),
          bobot,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response?.data?.similarity || 0; // Ambil nilai similarity dari response
    } catch (error) {
      console.error("Error in compareAnswers:", error);
      return 0; // Default similarity jika terjadi error
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (loading) return; // Cegah pengguna menekan berkali-kali jika `loading` masih true.

    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      // Gabungkan semua jawaban menjadi satu array tanpa mengubah struktur yang diharapkan
      // Fungsi untuk menghilangkan tanda baca
      const removePunctuation = (text) => {
        return text
          .replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, " ") // Ganti tanda baca dengan spasi
          .replace(/\s{2,}/g, " ") // Ganti spasi ganda menjadi satu spasi
          .trim(); // Hilangkan spasi di awal dan akhir
      };
      


      const combinedAnswers = await Promise.all(
        answers.map(async (answer) => {
          let mappedCorrectAnswers = "";
          let similarity = 0; // Inisialisasi nilai similarity  
      
          if (answer.type === "isian") {
            // Dapatkan jawaban yang benar untuk soal ini dari answer.optionCorrectAnswer
            const arrayCorrectAnswers = (answer.optionCorrectAnswer || [])
              .map((ans) => removePunctuation(ans.trim().toLowerCase())); // Hilangkan tanda baca dari jawaban benar
      
            mappedCorrectAnswers = arrayCorrectAnswers; 
      
            if (arrayCorrectAnswers.length) {
              // Ubah jawaban isian pengguna menjadi array, jika ada "|"
              const userAnswerArray = answer.text
                .split("|")
                .map((ans) => removePunctuation(ans.trim().toLowerCase())); // Hilangkan tanda baca dari jawaban pengguna
                console.log(arrayCorrectAnswers)
                console.log(userAnswerArray)
      
              // Hitung nilai similarity dengan memanggil fungsi compareAnswers
              const comparePromises = userAnswerArray.map(async (userAns) => {
                const highestSimilarity = await Promise.all(
                  arrayCorrectAnswers.map((correctAns) =>
                    compareAnswers(correctAns, userAns, answer.bobot, token)
                  )
                );
      
                // Ambil nilai similarity tertinggi dari hasil compareAnswers
                return Math.max(...highestSimilarity);
              });
      
              const similarities = await Promise.all(comparePromises);
              similarity = similarities.reduce((sum, value) => sum + value, 0) / arrayCorrectAnswers.length; // Rata-rata similarity
              similarity = parseFloat(similarity);
            }
          } else if (answer.type === "multipleChoice") {
            // Untuk soal pilihan ganda, jika isCorrect ada, berikan skor bobot
            similarity = answer.isCorrect ? answer.bobot : 0;
          } else if (answer.type === "essay") {
            // Gunakan fungsi compareAnswers untuk jawaban esai
            const correctAnswer = removePunctuation(answer.correctAnswer?.trim().toLowerCase());
            const userAnswer = removePunctuation(answer.text?.trim().toLowerCase());
      
            similarity = await compareAnswers(
              correctAnswer,
              userAnswer,
              answer.bobot,
              token
            );
          }
      
          // Format jawaban untuk dikirim ke server
          return {
            text: answer.text,
            type: answer.type,
            bobot: answer.bobot,
            gambar: answer.gambar || "",
            isCorrect: answer.isCorrect ? 1 : 0,
            optionCorrectAnswer: answer.type === "isian" ? mappedCorrectAnswers.join('|') : "", // Menggunakan string kosong jika bukan isian
            score: similarity,
          };
        })
      );
      
      console.log(combinedAnswers);
      // Kirim hasil ke server
      

      console.log(combinedAnswers);
      // Kirim hasil ke server

      // Kirim ke server
      await axios.post(
        `${process.env.REACT_APP_API_URL}api/answers`,
        { questionId, answers: combinedAnswers },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setSubmitted(true);
      navigate(`/siswa/elearning-siswa/${mapel}`);
    } catch (error) {
      console.error("Error submitting answer:", error);
    } finally {
      setLoading(false); // Nonaktifkan loading setelah proses selesai
    }
  };




  if (!questionData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="grid min-h-screen bg-blue-100">
      <div className="my-10 w-screen px-4">
        <div className="flex justify-center items-center my-10">
          <h2 className="font-bold text-3xl">
            {questionData.jenis} - {questionData.mapel}
          </h2>
        </div>
        <div className="flex justify-center items-center">
          <div className="bg-white p-5 rounded-lg w-[400px] md:w-[600px]">
            <form onSubmit={handleSubmit}>
              {questionData.question.map((q, index) => (
                <div key={q._id} className="mb-4">
                  <p className="text-xl mb-2">{`${index + 1}. ${q.text}`}</p>
                  {q.gambar && (
                    <img
                      src={`${q.gambar}`}
                      alt="Question"
                      className="mb-4 w-[400px] h-[200px]"
                    />
                  )}
                  {q.type === "multipleChoice" ? (
                    <div>
                      {q.options.map((option, idx) => (
                        <div key={idx} className="mb-2">
                          <input
                            type="radio"
                            id={`question${index}_option${idx}`}
                            name={`question${index}`}
                            value={option.text}
                            onChange={() =>
                              handleInputChange(
                                index,
                                option.text,
                                option.isCorrect
                              )
                            }
                            disabled={submitted}
                          />
                          <label
                            htmlFor={`question${index}_option${idx}`}
                            className="ml-2"
                          >
                            {option.text}
                          </label>
                          {option.gambar && (
                            <img
                              src={`${option.gambar}`}
                              alt="Option"
                              className="ml-2 w-[100px] h-[100px] rounded"
                            />
                          )}
                        </div>
                      ))}
                    </div>
                  ) : q.type === "isian" ? (
                    <div>
                      <p className="mb-2">Jawaban Anda:</p>
                      {Array.from({ length: q.keyJawabanBenar }).map(
                        (_, idx) => (
                          <div key={idx} className="mb-2 flex items-center">
                            <input
                              type="text"
                              className="w-full p-2 border border-slate-500 rounded-lg"
                              value={inputValues[index]?.[idx] || ""} // Ambil nilai sesuai nomor soal dan jawaban
                              onChange={(e) =>
                                handleInputChangeIsian(
                                  index,
                                  idx,
                                  e.target.value
                                )
                              } // Handle perubahan input
                              placeholder={`Jawaban ${idx + 1}`}
                              disabled={submitted} // Nonaktifkan jika sudah disubmit
                            />
                          </div>
                        )
                      )}
                    </div>
                  ) : (
                    <textarea
                      className="w-full p-2 border border-slate-500 rounded-lg"
                      rows="5"
                      value={essayAnswers[index] || ""}
                      onChange={(e) => handleEssayChange(index, e.target.value)}
                      disabled={submitted}
                    />
                  )}
                </div>
              ))}

              <div className="grid justify-center mt-4">
                {!submitted && (
                  <button
                    type="submit"
                    className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-700"
                    disabled={loading}
                  >
                    Submit Answer
                  </button>
                )}
                {submitted && (
                  <p>Anda sudah mengirimkan jawaban untuk soal ini.</p>
                )}
                <div>
                  {Hasil.map((answer) => {
                    const totalScore = answer.question.reduce((acc, q) => {
                      if (q.type === "multipleChoice") {
                        return acc + (q.isCorrect ? q.bobot : 0);
                      } else if (q.type === "essay") {
                        return acc + (q.similarity !== null ? q.similarity : 0);
                      } else if (q.type === "isian") {
                        return acc + (q.similarity !== null ? q.similarity : 0);
                      }
                      return acc;
                    }, 0);

                    return (
                      <div key={answer._id}>
                        <h2 className="flex justify-center font-bold py-4">
                          Total Nilai: {totalScore.toFixed(2)}
                        </h2>
                      </div>
                    );
                  })}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="flex justify-center mb-10">
        <Link to={`/siswa/elearning-siswa/${mapel}`}>
          <Back />
        </Link>
      </div>
    </div>
  );
};

export default AnswerQuestion;
