// App.js
import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import AdminDashboard from "./components/AdminDashboard";
import AnswerQuestion from "./components/AnswerQuestion";
import DataSekolah from "./components/dataSekolah/DataSekolah";
import UpdataeDataSekolah from "./components/dataSekolah/UpdataeDataSekolah";
import GuruDashboard from "./components/GuruDashboard";
import Header from "./components/Header";
import Home from "./components/Home";
import LoginForm from "./components/LoginForm";
import EditP5 from "./components/P5/EditP5";
import GuruP5 from "./components/P5/GuruP5";
import InputdataP5 from "./components/P5/InputdataP5";
import ListP5 from "./components/P5/ListP5";
import ListP5Rapor from "./components/P5/ListP5Rapor";
import RaporP5 from "./components/P5/RaporP5";
import ProtectedRoute from "./components/ProtectedRoute";
import Rapor from "./components/Rapor";
import HalamanUtama from "./components/RaporComponet/HalamanUtama";
import UserList from "./components/RaporComponet/UserList";
import SiswaDashboard from "./components/SiswaDashboard";
import EditAbsen from "./pages/Absen/EditAbsen";
import AddEkskul from "./pages/AddEkskul";
import AddQustion from "./pages/AddQuestionForm";
import AddUser from "./pages/AddUser";
import AdminList from "./pages/AdminList";
import CPadmin from "./pages/CPadmin";
import EditEkskul from "./pages/EditEkskul";
import EditQuestion from "./pages/EditQuestion";
import EditUser from "./pages/EditUser";
import EkskulAll from "./pages/EkskuAll";
import EkskulSatu from "./pages/EkskulSatu";
import ElearningGuru from "./pages/ElearningGuru";
import ElerningSiswa from "./pages/ElerningSiswa";
import GuruList from "./pages/GuruList";
import InputNilaiManual from "./pages/InputNilaiManual";

import Footer from "./components/Footer";
import Absensi from "./pages/Absen/Absensi";
import AddBerita from "./pages/Berita Sekolah/AddBerita";
import AllBerita from "./pages/Berita Sekolah/AllBerita";
import KelolaBerita from "./pages/Berita Sekolah/KelolaBerita";
import KURKUM from "./pages/KURKUM";
import KURKUMedit from "./pages/KURKUMedit";
import ListEkskul from "./pages/LIstEkskul";
import ListSiswa from "./pages/ListSiswa";
import Nilai1 from "./pages/Nilai1";
import ProfileSekolah from "./pages/ProfileSekolah";
import ProfileUser from "./pages/ProfileUser";
import RevisiNilaiSiswa from "./pages/RevisiNilaiSiswa";
import HalamanBerita from "./pages/Berita Sekolah/HalamanBerita";
const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [role, setRole] = useState("");
  const [routeKey, setRouteKey] = useState(0);
  const [lastVisitedPath, setLastVisitedPath] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const payload = JSON.parse(atob(token.split(".")[1]));
        setRole(payload.user.role);
        setIsLoggedIn(true);
      } catch (error) {
        console.error("Error decoding token:", error);
      }
    }
    const lastPath = localStorage.getItem("lastVisitedPath");
    if (lastPath) {
      setLastVisitedPath(lastPath);
    }
  }, []);

  const handleLogin = (username, password) => {
    fetch(`${process.env.REACT_APP_API_URL}api/auth/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username, password }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.token) {
          localStorage.setItem("token", data.token);
          try {
            const payload = JSON.parse(atob(data.token.split(".")[1]));
            setRole(payload.user.role);
            setIsLoggedIn(true);
            setRouteKey((prevKey) => prevKey + 1);
            localStorage.removeItem("lastVisitedPath");
            window.location.reload();
          } catch (error) {
            console.error("Error decoding token:", error);
          }
        } else {
          alert("Login failed. Please check your credentials.");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        alert("An error occurred while logging in.");
      });
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    setIsLoggedIn(false);
    setRole("");
    setRouteKey((prevKey) => prevKey + 1);
    localStorage.setItem("lastVisitedPath", lastVisitedPath);
  };

  return (
    <Router>
      <Header role={role} handleLogout={handleLogout} />
      <Routes key={routeKey}>
        <Route path="/" element={<Home isLoggedIn={isLoggedIn} />} />
        <Route
          path="/login"
          element={
            <LoginForm
              handleLogin={handleLogin}
              isLoggedIn={isLoggedIn}
              role={role}
            />
          }
        />
        <Route path="/profilsekolah" element={<ProfileSekolah />} />
        {/* Akses BERITA */}
        <Route path="/berita" element={<AllBerita />} />
        <Route path="/berita/:id" element={<HalamanBerita/>} />
        {/* Kelola BERITA */}

        <Route path="/ekskul" element={<EkskulAll />} />
        <Route path="/ekskul-satu/:id" element={<EkskulSatu />} />
        <Route
          path="/admin"
          element={
            <ProtectedRoute
              isLoggedIn={isLoggedIn}
              role={role}
              allowedRoles={["admin"]}
            >
              <AdminDashboard handleLogout={handleLogout} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/kelolaberita"
          element={
            <ProtectedRoute
              isLoggedIn={isLoggedIn}
              role={role}
              allowedRoles={["admin"]}
            >
              <KelolaBerita />
            </ProtectedRoute>
          }
        />
        <Route
          path="/addberita"
          element={
            <ProtectedRoute
              isLoggedIn={isLoggedIn}
              role={role}
              allowedRoles={["admin"]}
            >
              <AddBerita />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/listadmin"
          element={
            <ProtectedRoute
              isLoggedIn={isLoggedIn}
              role={role}
              allowedRoles={["admin"]}
            >
              <AdminList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/edit-user/:id"
          element={
            <ProtectedRoute
              isLoggedIn={isLoggedIn}
              role={role}
              allowedRoles={["admin"]}
            >
              <EditUser />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/listguru"
          element={
            <ProtectedRoute
              isLoggedIn={isLoggedIn}
              role={role}
              allowedRoles={["admin"]}
            >
              <GuruList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/guru/addquestion"
          element={
            <ProtectedRoute
              isLoggedIn={isLoggedIn}
              role={role}
              allowedRoles={["guru"]}
            >
              <AddQustion />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/listsiswa"
          element={
            <ProtectedRoute
              isLoggedIn={isLoggedIn}
              role={role}
              allowedRoles={["admin"]}
            >
              <ListSiswa />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/ektrakulikuler"
          element={
            <ProtectedRoute
              isLoggedIn={isLoggedIn}
              role={role}
              allowedRoles={["admin"]}
            >
              <ListEkskul />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/edit-ekskul/:id"
          element={
            <ProtectedRoute
              isLoggedIn={isLoggedIn}
              role={role}
              allowedRoles={["admin"]}
            >
              <EditEkskul />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/ektrakulikuler/add-ekskul"
          element={
            <ProtectedRoute
              isLoggedIn={isLoggedIn}
              role={role}
              allowedRoles={["admin"]}
            >
              <AddEkskul />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/add-user"
          element={
            <ProtectedRoute
              isLoggedIn={isLoggedIn}
              role={role}
              allowedRoles={["admin"]}
            >
              <AddUser />
            </ProtectedRoute>
          }
        />
        <Route
          path="/guru"
          element={
            <ProtectedRoute
              isLoggedIn={isLoggedIn}
              role={role}
              allowedRoles={["guru"]}
            >
              <GuruDashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/guru/elearning-guru/:mapel"
          element={
            <ProtectedRoute
              isLoggedIn={isLoggedIn}
              role={role}
              allowedRoles={["guru"]}
            >
              <ElearningGuru />
            </ProtectedRoute>
          }
        />
        <Route
          path="/guru/elearning-guru/:mapel/edit/:id"
          element={
            <ProtectedRoute
              isLoggedIn={isLoggedIn}
              role={role}
              allowedRoles={["guru"]}
            >
              <EditQuestion />
            </ProtectedRoute>
          }
        />
        <Route
          path="/siswa"
          element={
            <ProtectedRoute
              isLoggedIn={isLoggedIn}
              role={role}
              allowedRoles={["siswa"]}
            >
              <SiswaDashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/siswa/elearning-siswa/:mapel"
          element={
            <ProtectedRoute
              isLoggedIn={isLoggedIn}
              role={role}
              allowedRoles={["siswa"]}
            >
              <ElerningSiswa />
            </ProtectedRoute>
          }
        />
        <Route
          path="/guru/rapor"
          element={
            <ProtectedRoute
              isLoggedIn={isLoggedIn}
              role={role}
              allowedRoles={["guru"]}
            >
              <Rapor />
            </ProtectedRoute>
          }
        />
        <Route
          path="/guru/inputnilaimanual/:questionId"
          element={
            <ProtectedRoute
              isLoggedIn={isLoggedIn}
              role={role}
              allowedRoles={["guru"]}
            >
              <InputNilaiManual />
            </ProtectedRoute>
          }
        />
        <Route
          path="/addTP"
          element={
            <ProtectedRoute
              isLoggedIn={isLoggedIn}
              role={role}
              allowedRoles={["guru", "admin"]}
            >
              <KURKUM />
            </ProtectedRoute>
          }
        />
        <Route
          path="/absensi/edit/:id"
          element={
            <ProtectedRoute
              isLoggedIn={isLoggedIn}
              role={role}
              allowedRoles={["guru", "admin"]}
            >
              <EditAbsen />
            </ProtectedRoute>
          }
        />
        <Route
          path="/absensi/:id"
          element={
            <ProtectedRoute
              isLoggedIn={isLoggedIn}
              role={role}
              allowedRoles={["guru", "admin"]}
            >
              <Absensi />
            </ProtectedRoute>
          }
        />
        <Route
          path="/profile/:id"
          element={
            <ProtectedRoute
              isLoggedIn={isLoggedIn}
              role={role}
              allowedRoles={["guru", "admin", "siswa"]}
            >
              <ProfileUser />
            </ProtectedRoute>
          }
        />
        <Route
          path="/editp5/:id"
          element={
            <ProtectedRoute
              isLoggedIn={isLoggedIn}
              role={role}
              allowedRoles={["guru", "admin"]}
            >
              <EditP5 />
            </ProtectedRoute>
          }
        />
        <Route
          path="/rapord-deskripsi-add"
          element={
            <ProtectedRoute
              isLoggedIn={isLoggedIn}
              role={role}
              allowedRoles={["guru", "admin"]}
            >
              <UserList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/view-rapor/user_id/:id/:di/:sid/:tahun/:semester/:kelas/:rombel/:createdBy"
          element={
            <ProtectedRoute
              isLoggedIn={isLoggedIn}
              role={role}
              allowedRoles={["guru", "admin"]}
            >
              <HalamanUtama />
            </ProtectedRoute>
          }
        />
        <Route
          path="/datasekolah"
          element={
            <ProtectedRoute
              isLoggedIn={isLoggedIn}
              role={role}
              allowedRoles={["guru", "admin"]}
            >
              <DataSekolah />
            </ProtectedRoute>
          }
        />
        <Route
          path="/updatedatasekolah"
          element={
            <ProtectedRoute
              isLoggedIn={isLoggedIn}
              role={role}
              allowedRoles={["guru", "admin"]}
            >
              <UpdataeDataSekolah />
            </ProtectedRoute>
          }
        />
        <Route
          path="/listp5"
          element={
            <ProtectedRoute
              isLoggedIn={isLoggedIn}
              role={role}
              allowedRoles={["guru", "admin"]}
            >
              <RaporP5 />
            </ProtectedRoute>
          }
        />
        <Route
          path="/listp5/add"
          element={
            <ProtectedRoute
              isLoggedIn={isLoggedIn}
              role={role}
              allowedRoles={["guru", "admin"]}
            >
              <ListP5 />
            </ProtectedRoute>
          }
        />
        <Route
          path="/addP5"
          element={
            <ProtectedRoute
              isLoggedIn={isLoggedIn}
              role={role}
              allowedRoles={["guru", "admin"]}
            >
              <GuruP5 />
            </ProtectedRoute>
          }
        />
        <Route
          path="/listp5rapor"
          element={
            <ProtectedRoute
              isLoggedIn={isLoggedIn}
              role={role}
              allowedRoles={["guru", "admin"]}
            >
              <ListP5Rapor />
            </ProtectedRoute>
          }
        />
        <Route
          path="/cpadmin"
          element={
            <ProtectedRoute
              isLoggedIn={isLoggedIn}
              role={role}
              allowedRoles={["guru", "admin"]}
            >
              <CPadmin />
            </ProtectedRoute>
          }
        />
        <Route
          path="/listp5/inputdatap5"
          element={
            <ProtectedRoute
              isLoggedIn={isLoggedIn}
              role={role}
              allowedRoles={["guru", "admin"]}
            >
              <InputdataP5 />
            </ProtectedRoute>
          }
        />
        <Route
          path="/editTP/:id"
          element={
            <ProtectedRoute
              isLoggedIn={isLoggedIn}
              role={role}
              allowedRoles={["guru", "admin"]}
            >
              <KURKUMedit />
            </ProtectedRoute>
          }
        />
        <Route
          path="/guru/elearning-guru/:mapel/nilai/:questionId"
          element={
            <ProtectedRoute
              isLoggedIn={isLoggedIn}
              role={role}
              allowedRoles={["guru"]}
            >
              <Nilai1 />
            </ProtectedRoute>
          }
        />
        <Route
          path="/guru/jawaban-siswa/id/:id/question/:questionId"
          element={
            <ProtectedRoute
              isLoggedIn={isLoggedIn}
              role={role}
              allowedRoles={["guru"]}
            >
              <RevisiNilaiSiswa />
            </ProtectedRoute>
          }
        />
        <Route
          path="/siswa/elearning-siswa/:mapel/question/:questionId"
          element={<AnswerQuestion />}
        />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
