import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid"; // Import uuidv4 untuk membuat UUID
import Back from "../components/button/back";

const EditQuestion = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [question, setQuestion] = useState({
    kelas: "",
    rombel: "",
    semester: "",
    mapel: "",
    jenis: "",
    kode: "",
    question: [],
  });

  useEffect(() => {
    const fetchQuestion = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}api/questions/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const fetchedQuestion = response.data;
        if (fetchedQuestion.question.length > 0) {
          setQuestion(fetchedQuestion);
        } else {
          setQuestion({
            ...fetchedQuestion,
            question: [
              {
                text: "",
                type: "essay",
                bobot: 1,
                correctAnswer: "",
                options: [],
                optionCorrectAnswer: [],
                keyJawabanBenar: 1,
              },
            ],
          });
        }
      } catch (error) {
        console.error("Error fetching question:", error);
      }
    };

    fetchQuestion();
  }, [id]);

  const handleChange = (e) => {
    setQuestion({ ...question, [e.target.name]: e.target.value });
  };

  const handleQuestionChange = (e, index) => {
    const { name, value } = e.target;
    const updatedQuestions = [...question.question];
    updatedQuestions[index][name] = value;
    if (name === "type" && value === "essay") {
      updatedQuestions[index].options = [];
      updatedQuestions[index].correctAnswer = "";
    } else if (name === "type" && value === "multipleChoice") {
      updatedQuestions[index].correctAnswer = undefined;
    }
    else if (name === "type" && value === "isian") {
      updatedQuestions[index].keyJawabanBenar = "";
      updatedQuestions[index].optionCorrectAnswer = [];

    }
    setQuestion({ ...question, question: updatedQuestions });
  };
  const handleOptionIsianChange = (e, questionIndex, optionIndex) => {
    const updatedQuestions = [...question.question];
    updatedQuestions[questionIndex].optionCorrectAnswer[optionIndex] =
      e.target.value;
    setQuestion({ ...question, question: updatedQuestions });
  };

  const handleOptionChange = (e, questionIndex, optionIndex) => {
    const { name, value, checked } = e.target;
    const updatedQuestions = [...question.question];
    const updatedOptions = [...updatedQuestions[questionIndex].options];
    if (name === "text") {
      updatedOptions[optionIndex].text = value;
    } else if (name === "isCorrect") {
      updatedOptions[optionIndex].isCorrect = checked;
    } else if (name === "gambar") {
      // Handle image upload for options
      handleOptionImageUpload(e, questionIndex, optionIndex);
      return; // Return early after handling image upload
    }
    updatedQuestions[questionIndex].options = updatedOptions;
    setQuestion({ ...question, question: updatedQuestions });
  };

  const handleOptionImageUpload = async (e, questionIndex, optionIndex) => {
    const file = e.target.files[0]; // Ambil file dari input
    if (!file) return;
  
    const fileName = `${uuidv4()}-${file.name}`; // Buat nama file unik
    const formData = new FormData();
    formData.append("image", file); // Tambahkan file ke FormData
    formData.append("fileName", fileName); // Tambahkan nama file unik
    formData.append(
      "oldImage",
      question.question[questionIndex]?.options[optionIndex]?.gambar || ""
    ); // Kirim URL gambar lama jika ada
  
    try {
      // Kirim FormData ke API menggunakan Axios
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}api/questions/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
  
      // Ambil URL gambar baru dari respons backend
      const imageUrl = response.data.image_url;
  
      // Perbarui state dengan URL gambar baru
      const updatedQuestions = [...question.question];
      updatedQuestions[questionIndex].options[optionIndex].gambar = imageUrl;
  
      setQuestion({ ...question, question: updatedQuestions });
      console.log(`Option image uploaded successfully: ${imageUrl}`);
    } catch (error) {
      // Tangani kesalahan jika upload gagal
      if (error.response) {
        console.error("Error uploading option image:", error.response.data);
      } else {
        console.error("Error uploading option image:", error.message);
      }
    }
  };
  
  const addOption = (index) => {
    const updatedQuestions = [...question.question];
    updatedQuestions[index].options.push({ text: "", isCorrect: false });
    setQuestion({ ...question, question: updatedQuestions });
  };

  const removeOption = (questionIndex, optionIndex) => {
    const updatedQuestions = [...question.question];
    updatedQuestions[questionIndex].options.splice(optionIndex, 1);
    setQuestion({ ...question, question: updatedQuestions });
  };

  const addQuestion = () => {
    const newQuestion = {
      text: "",
      type: "essay",
      bobot: 1,
      correctAnswer: "",
      keyJawabanBenar:1,
      options: [],
    };
    const updatedQuestions = [...question.question, newQuestion];
    const newKode = question.kode ? `${parseInt(question.kode) + 1}` : "1";
    setQuestion((prevState) => ({
      ...prevState,
      kode: newKode,
      question: updatedQuestions,
    }));
  };

  const removeQuestion = (index) => {
    const updatedQuestions = [...question.question];
    updatedQuestions.splice(index, 1);
    const newKode = question.kode ? `${parseInt(question.kode) - 1}` : "1";
    setQuestion({ ...question, kode: newKode, question: updatedQuestions });
  };

  const handleImageUpload = async (e, index) => {
    const file = e.target.files[0]; // Ambil file dari input
    if (!file) return;
  
    const fileName = `${uuidv4()}-${file.name}`; // Buat nama file unik
    const formData = new FormData();
    formData.append("image", file); // Menambahkan file ke FormData
    formData.append("fileName", fileName); // Menambahkan nama file ke FormData
    formData.append("oldImage", question.question[index]?.gambar || ""); // Kirim gambar lama jika ada
  
    try {
      // Kirim FormData ke API menggunakan Axios
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}api/questions/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
  
      // Ambil URL gambar yang dikembalikan oleh backend
      const imageUrl = response.data.image_url;
  
      // Perbarui state dengan URL gambar baru
      const updatedQuestions = [...question.question];
      updatedQuestions[index].gambar = imageUrl;
  
      setQuestion({ ...question, question: updatedQuestions });
      console.log("Image uploaded successfully:", imageUrl);
    } catch (error) {
      // Tangani kesalahan jika upload gagal
      if (error.response) {
        console.error("Error uploading image:", error.response.data);
      } else {
        console.error("Error uploading image:", error.message);
      }
    }
  };
  
  // Fungsi untuk menambahkan option baru
  const handleAddOption = (questionIndex) => {
    const updatedQuestions = [...question.question];
    updatedQuestions[questionIndex].optionCorrectAnswer.push("");
    setQuestion({ ...question, question: updatedQuestions });
  };

  // Fungsi untuk menghapus option
  const handleRemoveOption = (questionIndex, optionIndex) => {
    const updatedQuestions = [...question.question];
    updatedQuestions[questionIndex].optionCorrectAnswer.splice(optionIndex, 1);
    setQuestion({ ...question, question: updatedQuestions });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("token");
      await axios.put(
        `${process.env.REACT_APP_API_URL}api/questions/${id}`,
        question,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      navigate(`/guru/elearning-guru/${question.mapel}`);
    } catch (error) {
      console.error("Error updating question:", error);
    }
  };

  return (
    <div className=" grid min-h-screen w-screen bg-blue-100">
      <div className="grid justify-center py-5">
        <h2 className="text-2xl font-bold">Edit Pertanyaan</h2>
      </div>
      <div className=" flex justify-center w-screen">
        <div className="grid justify-center bg-white p-5 rounded-lg">
          <form onSubmit={handleSubmit}>
            <div>
              <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 border border-slate-500 rounded-md p-4">
                <div>
                  <label className="font-bold">Kelas : </label>
                  <select
                    name="kelas"
                    value={question.kelas}
                    onChange={handleChange}
                    required
                    className="rounded-lg py-2 px-4 border-2 w-full"
                  >
                    <option value="">Kelas</option>
                    {["1", "2", "3", "4", "5", "6"].map((kelas) => (
                      <option key={kelas} value={kelas}>
                        {kelas}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label className="font-bold">Rombel : </label>
                  <select
                    name="rombel"
                    value={question.rombel}
                    onChange={handleChange}
                    required
                    className="rounded-lg px-4 py-2 border-2 w-full"
                  >
                    <option value="">Rombel</option>
                    {["A", "B"].map((rombel) => (
                      <option key={rombel} value={rombel}>
                        {rombel}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label className="font-bold">Semester : </label>
                  <select
                    name="semester"
                    value={question.semester}
                    onChange={handleChange}
                    required
                    className="rounded-lg px-4 py-2 border-2 w-full"
                  >
                    <option value="">Semester</option>
                    {["1", "2"].map((semester) => (
                      <option key={semester} value={semester}>
                        {semester}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label className="font-bold">Mapel : </label>
                  <select
                    name="mapel"
                    value={question.mapel}
                    onChange={handleChange}
                    required
                    className="rounded-lg px-4 py-2 border-2 w-full"
                  >
                    <option value="">Mapel</option>
                    {[
                      "IPA",
                      "IPS",
                      "MTK",
                      "BIndo",
                      "BIng",
                      "IPAS",
                      "PAI",
                      "PKN",
                      "PJOK",
                      "SENIBUDAYA",
                      "MULOK",
                    ].map((mapel) => (
                      <option key={mapel} value={mapel}>
                        {mapel}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label className="font-bold">Jenis : </label>
                  <select
                    name="jenis"
                    value={question.jenis}
                    onChange={handleChange}
                    required
                    className="rounded-lg px-4 py-2 border-2 w-full"
                  >
                    <option value="">Jenis</option>
                    {[
                      "Latihan",
                      "Tugas",
                      "PR",
                      "UH",
                      "MID",
                      "US",
                      "Praktikum",
                    ].map((jenis) => (
                      <option key={jenis} value={jenis}>
                        {jenis}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label className="font-bold">Jumlah soal : </label>
                  <div className="rounded-lg px-4 py-2 w-full">
                    {question.kode}
                  </div>
                </div>
              </div>
                    
              <div>
                <ul>
                  {question.question?.map((q, index) => (
                    <li
                      key={index}
                      className="grid gap-y-2 border border-slate-500 mt-2 p-2 rounded-lg"
                    >
                      <h3>No. {index + 1}</h3>
                      {/* Display the question number */}
                      <div className="grid">
                        <label>Pertanyaan:</label>
                        <textarea
                          type="text"
                          name="text"
                          value={q.text}
                          className="border border-slate-400"
                          onChange={(e) => handleQuestionChange(e, index)}
                        />
                      </div>
                      <div>
                        <label>Upload Gambar:</label>
                        <input
                          type="file"
                          onChange={(e) => handleImageUpload(e, index)}
                        />
                        {q.gambar && (
                          <div>
                            <img
                              src={q.gambar}
                              alt={`Gambar Pertanyaan ${index + 1}`}
                              style={{ width: "100px", height: "auto" }}
                            />
                          </div>
                        )}
                      </div>
                      <div>
                        <label>Jenis : </label>
                        <select
                          name="type"
                          value={q.type}
                          className="border border-slate-500 px-3 rounded-lg"
                          onChange={(e) => handleQuestionChange(e, index)}
                        >
                          <option value="essay">Essay</option>
                          <option value="multipleChoice">Pilihan Ganda</option>
                          <option value="isian">Isian</option>
                        </select>
                      </div>
                      <div>
                        <label>Bobot : </label>
                        <input
                          type="number"
                          name="bobot"
                          value={q.bobot}
                          className="border border-slate-500 px-3 rounded-lg"
                          onChange={(e) => handleQuestionChange(e, index)}
                        />
                      </div>
                      {q.type === "multipleChoice" && (
                        <div>
                          <label>Opsi Jawaban:</label>
                          <ul>
                            {q.options?.map((option, i) => (
                              <li key={i} className="flex gap-4 my-2">
                                <input
                                  type="text"
                                  name="text"
                                  className="border border-slate-500 rounded-md"
                                  value={option.text}
                                  onChange={(e) =>
                                    handleOptionChange(e, index, i)
                                  }
                                />
                                <label className="flex gap-1">
                                  <input
                                    type="checkbox"
                                    name="isCorrect"
                                    checked={option.isCorrect}
                                    onChange={(e) =>
                                      handleOptionChange(e, index, i)
                                    }
                                  />
                                  <p>Benar</p>
                                </label>
                                <div>
                                  <label>Upload Gambar:</label>
                                  <input
                                    type="file"
                                    name="gambar"
                                    onChange={(e) =>
                                      handleOptionChange(e, index, i)
                                    }
                                  />
                                  {option.gambar && (
                                    <div>
                                      <img
                                        src={option.gambar}
                                        alt={`Gambar Opsi ${i + 1}`}
                                        style={{
                                          width: "100px",
                                          height: "auto",
                                        }}
                                      />
                                    </div>
                                  )}
                                </div>
                                <button
                                  className="bg-red-400 rounded-lg py-1 px-2"
                                  type="button"
                                  onClick={() => removeOption(index, i)}
                                >
                                  Hapus Opsi
                                </button>
                              </li>
                            ))}
                          </ul>
                          <button
                            type="button"
                            onClick={() => addOption(index)}
                          >
                            Tambah Opsi
                          </button>
                        </div>
                      )}
                      {q.type === "essay" && (
                        <div className="grid">
                          <label>Jawaban Benar:</label>
                          <textarea
                            type="text"
                            name="correctAnswer"
                            value={q.correctAnswer || ""}
                            className="border border-slate-500 px-3 rounded-lg"
                            onChange={(e) => handleQuestionChange(e, index)}
                          />
                        </div>
                      )}
                      {q.type === "isian" && (
                        <div className="grid">
                          {/* Input untuk keyJawabanBenar */}
                          <label>Berapa Jawaban Benar:</label>
                          <input
                            type="number"
                            name="keyJawabanBenar"
                            value={q.keyJawabanBenar || ""}
                            className="border border-slate-500 px-3 rounded-lg"
                            onChange={(e) => handleQuestionChange(e, index)}
                            min={1}
                          />

                          {/* Input untuk optionCorrectAnswer */}
                          <label>Option Correct Answer:</label>
                          {q.optionCorrectAnswer.map((answer, idx) => (
                            <div
                              key={idx}
                              className="flex items-center space-x-2 mb-2"
                            >
                              <input
                                type="text"
                                name={`optionCorrectAnswer-${idx}`}
                                value={answer || ""}
                                className="border border-slate-500 px-3 rounded-lg"
                                onChange={(e) =>
                                  handleOptionIsianChange(e, index, idx)
                                }
                              />
                              <button
                                type="button"
                                className="bg-red-500 text-white px-2 py-1 rounded"
                                onClick={() => handleRemoveOption(index, idx)}
                              >
                                Hapus
                              </button>
                            </div>
                          ))}

                          {/* Tombol untuk menambah option */}
                          <button
                            type="button"
                            className="bg-blue-500 text-white px-3 py-2 rounded"
                            onClick={() => handleAddOption(index)}
                          >
                            Tambah Option
                          </button>
                        </div>
                      )}

                      <div className="flex justify-center mt-4 bg-red-400 rounded-md py-2">
                        <button
                          type="button"
                          onClick={() => removeQuestion(index)}
                        >
                          Hapus Pertanyaan
                        </button>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="flex justify-center py-2 bg-green-300 my-5 rounded-md">
                <button type="button" onClick={addQuestion}>
                  Tambah Pertanyaan
                </button>
              </div>
            </div>{" "}
            <div className="flex justify-center bg-blue-400 py-2 rounded-md">
              <button type="submit">Submit</button>
            </div>
          </form>
        </div>
      </div>

      <div className="flex justify-center my-10">
        <Link to={"/guru"}>
          <Back />
        </Link>
      </div>
    </div>
  );
};

export default EditQuestion;
