import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ProfileGuru from "./Profile/ProfileGuru";
import ProfileSiswa from "./Profile/ProfileSiswa";



const ProfileUser = () => {
  const { id } = useParams(); // Ambil ID dari URL
  const [jabatan, setJabatan] = useState([]);
  const [user, setUser] = useState({});
  const [role, setRole] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);


  useEffect(() => {
    const token = localStorage.getItem("token");
    // Fetch data user
    if (token && id) {
      axios
        .get(`${process.env.REACT_APP_API_URL}api/auth/users/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setUser(response.data);
          setRole(response.data.role); // Set dummy nilai
          setJabatan(response.data.jabatan)
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching user profile:", error.message);
          setError("Failed to load user data.");
          setLoading(false);
        });

    }
  }, [id]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="bg-gray-50 min-h-screen py-10 px-5">
      {role === "guru" ? (
        <h1 className="text-4xl font-bold text-center text-gray-800 mb-8">
          Profile Guru
        </h1>
      ) : (role === "siswa" || role === "admin") ? (
        <h1 className="text-4xl font-bold text-center text-gray-800 mb-8">
          Profile
        </h1>
      ) : null}



      <div className="flex flex-col lg:flex-row bg-white shadow-lg rounded-lg p-6 mb-8">
        <div className="flex justify-center items-center w-full lg:w-1/4 mb-6 lg:mb-0">
          <img
            src={user.image || "https://via.placeholder.com/150"}
            alt="Profile"
            className="rounded-full w-32 h-32 object-cover"
          />
        </div>
        <div className="lg:w-3/4">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">
            {user.name}
          </h2>
          <p>
            <strong>ID:</strong> {user.id}
          </p>
          {role === "guru" && jabatan === "Guru Kelas" ? (
            <p>
              <strong>Guru Kelas:</strong> {user.kelas} {user.rombel}
            </p>
          ) : jabatan === "Guru Mapel" ? (
            <p>
              <strong>Guru Mapel:</strong> {user.mapel}
            </p>
          ) : (
            <p>
              <strong>Kelas:</strong> {user.kelas} {user.rombel}
            </p>
          )}
        </div>
      </div>

      {/* Diagram Batang Nilai */}
      {role === "siswa" ? (
        <div>
          <ProfileSiswa id={id} />
        </div>
      ) : role === "guru" && jabatan !== "Kepala Sekolah" ? (
        <div>
          <ProfileGuru id={id} />
        </div>
      ) : null}
    </div>
  );
};

export default ProfileUser;
